import {
  ADD_USER_TO_SEGMENT_SUCCESS,
  CLEAR_SELECTED_VOUCHER,
  DISABLE_ADD_USER_TO_SEGMENT,
  ENABLE_ADD_USER_TO_SEGMENT,
  SET_APPLIED_VOUCHER,
  SET_VOUCHERS,
} from './promotionsTypes';
import PromotionsService from '@apiService/PromotionsService';
import { toast } from 'react-toastify';
import _ from 'lodash';
import i18n from 'i18next';

export const setVouchers = (payload) => ({
  type: SET_VOUCHERS,
  payload,
});

export const setVouchersError = (payload) => ({
  type: SET_VOUCHERS,
  payload,
});

export const setAppliedVoucher = (payload) => ({
  type: SET_APPLIED_VOUCHER,
  payload,
});

export const fetchVouchers = (payload) => async (dispatch, getState) => {
  const state = getState();
  let scheduleDetails = state.movies.scheduleDetails;

  console.log('payload for promotions', payload);
  console.log('scheduleDetails', scheduleDetails);

  const s = {
    cinema_id: scheduleDetails.cine_id,
    movie_details_id: scheduleDetails.movie_details_id,
    screen_id: scheduleDetails.screen_id,
    seat_type_id: payload.seat.sst_id,
    show_date_time: scheduleDetails.ss_start_date_time,
    aggregator_cinema_id: scheduleDetails.cine_id,
  };

  PromotionsService.GetVouchersList(s)
    .then((response) => {
      const { data } = response;
      if (data && data.status) {
        dispatch(setVouchers(data.Records));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setVouchers(err.response ? err.response.data?.message : err.message),
      );
    });
};

export const validateVoucherCode = (payload) => async (dispatch, getState) => {
  try {
    console.log({ voucherPayload: payload });
    const state = getState();
    const response = await PromotionsService.ValidateVoucherCodeV2(payload);

    const { data } = response;
    console.log({ voucherPayloadResponse: response });

    if (data && data.status && data.Records.length > 0) {
      let selectedVoucher = data.Records[0];
      // const discount = calculateVoucherDiscount({
      //   selectedVoucher,
      //   totalAmount,
      //   netAmount,
      // });
      const discount = data?.BoxOffice?.TotalOfferPrice ?? 0;
      console.log({ voucher_discount: discount });
      selectedVoucher.voucher_discount = discount;
      dispatch(
        setAppliedVoucher({
          ...selectedVoucher,
          BoxOffice: data.BoxOffice,
        }),
      );
    } else {
      // toast.error(`Invalid Promo Code`);
      throw new Error('Invalid Promo Code');
    }

    return {
      is100PercentOff:
        data?.BoxOffice?.actualTotalGross === 0 ||
        data?.BoxOffice?.actualTotalGross < 0,
      status: true,
    };
  } catch (err) {
    console.log({ voucherPayloadErr: err });
    return {
      is100PercentOff: false,
      status: false,
      message: err.message,
    };
  }
};

const calculateVoucherDiscount = ({ selectedVoucher, totalAmount, netAmount }) => {
  let voucherDiscount = 0;

  if (selectedVoucher.voucher_is_percentage === 'Y') {
    voucherDiscount =
      selectedVoucher.voucher_redeem_on_gross === 'Y'
        ? (selectedVoucher.voucher_amount / 100) * totalAmount
        : selectedVoucher.voucher_redeem_on_net === 'Y'
        ? (selectedVoucher.voucher_amount / 100) * netAmount
        : 0;
  } else {
    voucherDiscount = selectedVoucher.voucher_amount;
  }

  if (voucherDiscount > selectedVoucher.voucher_max_redemption_amount) {
    voucherDiscount = selectedVoucher.voucher_max_redemption_amount;
  } else if (voucherDiscount < selectedVoucher.voucher_min_redemption_amount) {
    voucherDiscount = selectedVoucher.voucher_min_redemption_amount;
  }

  voucherDiscount = _.round(voucherDiscount, 2);

  return voucherDiscount;
};

export const clearSelectedVoucher = () => async (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_VOUCHER });

export const addUserToSegment = (payload) => async (dispatch) => {
  dispatch({ type: DISABLE_ADD_USER_TO_SEGMENT });
  try {
    let { data } = await PromotionsService.AddCustomerToSegment(payload);

    if (payload.segment_type !== 'VIEW') {
      dispatch({
        type: ADD_USER_TO_SEGMENT_SUCCESS,
        payload: i18n.t('Thank you for showing interest. We will inform you!'),
      });
    }
  } catch (err) {
    if (payload.segment_type !== 'VIEW') {
      if (err?.response?.data?.message === 'Already exist') {
        // toast.success(
        //   i18n.t("Thank you for showing interest. We will inform you!")
        // );
        dispatch({
          type: ADD_USER_TO_SEGMENT_SUCCESS,
          payload: i18n.t('Thank you for showing interest. We will inform you!'),
        });
      }
    }
  }
  dispatch({ type: ENABLE_ADD_USER_TO_SEGMENT });
};
