import React, { useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../../customHooks/useIsMobile';
import { useLocation } from 'react-router-dom';
import Button from '@components/partials/Button';
// Images
import giftCardImg from '@assets/pictures/gift-card.png';
import { useSelector } from 'react-redux';
import Spinner from '../../partials/Spinner';

const CashCardBuyInfo = (props) => {
  const {
    t,
    classes = '',
    nameToPrint = '',
    cardImage = '',
    onClickNext = () => {},
  } = props;
  const { pathname } = useLocation();
  const isMobile = useIsMobile();

  const [showCheckout, setShowCheckout] = useState(false);
  const giftCardBottomRef = useRef();
  const giftCardInfoRef = useRef();

  useEffect(() => {
    if (
      pathname.startsWith('/gift-card-payment') ||
      pathname.startsWith('/gift-card-topup')
    ) {
      setShowCheckout(true);
    } else {
      setShowCheckout(false);
    }
  }, [pathname]);

  useEffect(() => {
    const setGiftCardBottomWidth = () => {
      const giftCardBottom = giftCardBottomRef.current;
      const giftCardInfo = giftCardInfoRef.current;
      if (giftCardBottom && giftCardInfo) {
        giftCardBottom.style.width = giftCardInfo.offsetWidth + 'px';
      }
    };
    setGiftCardBottomWidth();
    window.addEventListener('resize', setGiftCardBottomWidth);
    return () => window.removeEventListener('resize', setGiftCardBottomWidth);
  }, [giftCardBottomRef.current, giftCardInfoRef.current]);

  const websiteCashCard = useSelector((s) => s.cashCard.websiteCashCard) ?? [];
  const websiteCashCardLoading =
    useSelector((s) => s.cashCard.websiteCashCardLoading) ?? false;
  const cashCardBuySelection = useSelector(
    (state) => state.cashCard.cashCardBuySelection,
  );

  if (websiteCashCardLoading)
    return (
      <div className="row">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    );

  return (
    <>
      <div
        className={`gift_card_info_wrapper movie_info_wrapper payment_details_new ${
          isMobile ? 'sticky_movie_info' : ''
        }`}
        ref={giftCardInfoRef}
      >
        <article className="gift_card_info">
          <figure>
            <img
              src={
                !!cardImage
                  ? cardImage
                  : websiteCashCard?.w_cash_card_image_url || giftCardImg
              }
              alt="websiteCashCard?.w_cash_card_image_url"
              onError={({ currentTarget }) => {
                currentTarget.onerror = () => {}; // prevents looping
                currentTarget.src = giftCardImg;
              }}
            />
            <h5
              className="text_image"
              style={{ color: `${websiteCashCard?.w_font_color || 'white'}` }}
            >
              {!!nameToPrint
                ? nameToPrint
                : !!cashCardBuySelection?.nameToPrint
                ? cashCardBuySelection?.nameToPrint
                : '...'}
            </h5>
          </figure>
          <section className="">
            <h6>{t('Name or Text to be printed')}</h6>
            <p style={{ color: 'bllack' }}>
              {!!nameToPrint
                ? nameToPrint
                : !!cashCardBuySelection?.nameToPrint
                ? cashCardBuySelection?.nameToPrint
                : '...'}
            </p>
            {isMobile && (
              <article className="col-xl-6">
                <Button
                  text="Next"
                  size="lg"
                  classes={`mx-auto mx-xl-0 mt-3`}
                  onClick={onClickNext}
                />
              </article>
            )}
          </section>
          {/*
          <section>
            <h6>How to avail gift cards</h6>
            <ul className="pl-4">
              {Array(3).fill(1).map((item, index) => (
                <li key={index}>
                  Text goes here
                </li>)
              )}
            </ul>
          </section>
*/}
          {showCheckout && (
            <>
              <section className="text-white">
                <h6>Checkout summary</h6>
                <article className="payment_details_rows">
                  {Array(2)
                    .fill(0)
                    .map((seatType, seatTypeIndex) => (
                      <>
                        {Array(2)
                          .fill(0)
                          .map((rcg_child, index2) => (
                            <section className="">
                              <p className="left_value">
                                <span className="ml-0">(x6 Cards)</span>

                                <>
                                  <p className="d-inline text-nowrap">
                                    &nbsp;Gift Card
                                  </p>
                                </>
                                <span className="">Card Name</span>
                              </p>

                              <p className="right_value">CHF 20.00</p>
                            </section>
                          ))}
                      </>
                    ))}
                </article>
              </section>
              {isMobile && (
                <article className="payment_details_rows payment_details_total">
                  <section className="">
                    <h5>{t('Grand Total')}</h5>
                    <h4 className="">CHF 201.00</h4>
                  </section>
                  <h6>{t('(Incl. of 2.5% VAT - ')} CHF 1.00</h6>
                </article>
              )}
            </>
          )}
        </article>

        {!isMobile && showCheckout && (
          <>
            <article className="movie_info_scroll_space" />
            <article className="movie-bottom" ref={giftCardBottomRef}>
              <section className="">
                <div>
                  <h5>{t('Grand Total')}</h5>
                  <h4 className="">CHF 201.00</h4>
                </div>

                <h6>{t('(Incl. of 2.5% VAT - ')} CHF 1.00</h6>
              </section>
            </article>
          </>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(CashCardBuyInfo);
