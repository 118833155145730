import React from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import { array } from 'yup';
import { useSelector } from 'react-redux';
import { getLangSpecificAttribute } from '@helper/languages';
import BreadCrumb from '@components/partials/BreadCrumbs';

const TariffsAndVouchers = (props) => {
  const { t, classes = '' } = props;
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  let tariffs = [
    {
      header: [
        { title: `${t('Prices_at_the_Petro_Cinemas')}`, subTitle: '' },
        // { title: `${t("Auditorium")} 1`, subTitle: "" },
        // { title: `${t("Auditorium")} 2`, subTitle: "" },
      ],
      body: [
        {
          title: `${t('Adult_pet')}`,
          subTitle: ``,
          tariff: [
            {
              value: `${t('Six_6')}` + `${t('Zero_0')}` + `${t(' SAR')}`,
            },
          ],
        },
        {
          title: `${t('Kids_pet')}`,
          tariff: [{ value: `${t('Four_4')}` + `${t('Five_5')}` + `${t(' SAR')}` }],
        },
      ],
    },
  ];

  return (
    <>
      <section className="tariffs_and_vouchers align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('more_pet')} >`}
            secondHalf={t('Our Tariff')}
          />
          <CenteredTitle firstHalf={t('Our_Tariff_pet ')} secondHalf="" />
        </div>
        <div className="row">
          <article className="col-lg-10 col-xl-8 mx-auto">
            <article className=" tariffs_and_vouchers_cards_wrapper">
              <>
                {/* <h6>
                {"Tariffs"}
            </h6> */}
              </>
            </article>
          </article>
        </div>

        {/* TABLE */}
        {tariffs.map((tariff, index) => (
          <div
            className="tariff_table"
            key={index}
            style={{
              direction: selected_language.lang_id == 3 ? 'rtl' : 'ltr',
              textAlign: selected_language.lang_id == 3 ? 'right' : '',
            }}
          >
            <table>
              <thead>
                <tr>
                  {tariff.header.map((head, headIndex) => (
                    <th key={headIndex.length}>
                      <h6>{head.title}</h6>
                      <p>{head.subTitle}</p>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tariff.body.map((data, dataindex) => (
                  <tr key={dataindex}>
                    <td>
                      <h6>{data.title}</h6>
                      <p>{data.subTitle}</p>
                    </td>
                    {data.tariff.map((t, tindex) => (
                      <td
                        key={tindex}
                        style={{
                          textAlign: selected_language.lang_id == 3 ? 'left' : '',
                        }}
                      >
                        <div className={t.value && 'tariff_code'}>{t.value}</div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <div className="tarrif_para d-none">
          <p className="tariff_highlight mb-3">
            {t('Prices can be adjusted at any time without prior notice.')}
          </p>
          <p className="tariff_highlight">
            {t(
              'Additional costs such as technicians, additional infrastructure, etc. will be charged at cost.',
            )}
          </p>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(TariffsAndVouchers);
