import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getLangSpecificAttribute } from '@helper/languages';

const BreadCrumb = (props) => {
  const { t, classes = '', firstHalf = '', secondHalf = '' } = props;
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  return (
    <>
      <h1
        className={`bread_crumbs ${classes}`}
        style={{
          direction: selected_language.lang_id == 3 ? 'rtl' : 'ltr',
          textAlign: selected_language.lang_id == 3 ? 'right' : '',
        }}
      >
        <span style={{ textTransform: 'uppercase' }}>{firstHalf}</span>&nbsp;
        <span style={{ textTransform: 'capitalize' }}>{secondHalf}</span>
      </h1>
    </>
  );
};

export default withNamespaces()(BreadCrumb);
