import Api from './Api';
import { createQueryString } from './ApiHelper';

export default {
  GetAllFnbCategories(payload = {}) {
    let query = createQueryString(payload);
    return Api().get(`/api/external/fnb-categories/${query}`);
  },
  GetAllFnbItems(payload) {
    return Api().post(`/api/external/fnb-items`, payload);
  },
  GetModifierGroup(payload) {
    return Api().get(`fnb/modifier-group/${payload.item_id}`);
  },
  GetAllModifiers(payload) {
    return Api().get(`fnb/modifiers/${payload.item_id}`);
  },
  AddFnb(payload) {
    return Api().get(`fnb/add-fnb`, payload);
  },
  SaveFnbItems(payload) {
    return Api().post(`/api/external/save-fnb-items`, payload);
  },
  BookFnb(payload) {
    return Api().post(`/api/external/book-fnb`, payload);
  },
};
