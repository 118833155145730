import {
  SET_VOUCHERS,
  SET_APPLIED_VOUCHER,
  CLEAR_VOUCHERS_DATA,
  SET_VOUCHERS_ERROR,
  CLEAR_SELECTED_VOUCHER,
  ENABLE_ADD_USER_TO_SEGMENT,
  DISABLE_ADD_USER_TO_SEGMENT,
  ADD_USER_TO_SEGMENT_SUCCESS,
} from './promotionsTypes';

const initialState = {
  vouchers: [],
  vouchers_error: null,
  applied_voucher: null,
  offer_type: null,
  voucher_discount: null,
  addUserToSegmentBool: true,
  addUserToSegmentMsg: '',
};

const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VOUCHERS: {
      return {
        ...state,
        vouchers: action.payload,
      };
    }
    case SET_VOUCHERS_ERROR: {
      return {
        ...state,
        vouchers: [],
        vouchers_error: action.payload,
        offer_type: null,
        voucher_discount: null,
      };
    }
    case SET_APPLIED_VOUCHER: {
      return {
        ...state,
        applied_voucher: action.payload,
        offer_type: 'vouchers',
        voucher_discount: action.payload.voucher_discount,
      };
    }
    case CLEAR_VOUCHERS_DATA: {
      return {
        ...state,
        vouchers: [],
        applied_voucher: null,
        offer_type: null,
        voucher_discount: null,
      };
    }
    case CLEAR_SELECTED_VOUCHER:
      return {
        ...state,
        applied_voucher: null,
        offer_type: null,
        voucher_discount: null,
      };
    case ENABLE_ADD_USER_TO_SEGMENT:
      return {
        ...state,
        addUserToSegmentBool: true,
      };
    case DISABLE_ADD_USER_TO_SEGMENT:
      return {
        ...state,
        addUserToSegmentBool: false,
      };
    case ADD_USER_TO_SEGMENT_SUCCESS:
      return {
        ...state,
        addUserToSegmentMsg: action.payload,
      };
    default:
      return state;
  }
};

export default promotionsReducer;
