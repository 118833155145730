import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import Button from '@components/partials/Button';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import { setShowLoginPopup } from '../../../store/auth/authActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getLangSpecificAttribute } from '@helper/languages';

const AboutUsHeroText = (props) => {
  const { t, classes = '' } = props;
  const [register, setRegister] = useState(false);

  const dispatch = useDispatch();
  const handleLoginPopup = (toShow) => {
    setRegister(toShow);
    dispatch(setShowLoginPopup(toShow));
  };
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  return (
    <>
      <section className={`container-fluid about_us_hero_text ${classes}`}>
        <div className="row">
          <article className="col-lg-10 col-xl-12 mx-auto">
            <BreadCrumb
              firstHalf={`${t('more_pet')} >`}
              secondHalf={t('About_us_pet')}
            />
            <CenteredTitle firstHalf={t('About_us_pet')} secondHalf={''} />
            <div className="about_us_align">
              <p
                className="about_us_hero_text_synopsis"
                style={{
                  direction: selected_language.lang_id == 3 ? 'rtl' : 'ltr',
                  textAlign: selected_language.lang_id == 3 ? 'right' : '',
                }}
              >
                {/* {t('AboutUs.headerContent')} */}
                {t('About_us_abrand')}
              </p>
              <p
                className="about_us_hero_text_movie_details"
                style={{
                  direction: selected_language.lang_id == 3 ? 'rtl' : 'ltr',
                  textAlign: selected_language.lang_id == 3 ? 'right' : '',
                }}
              >
                {/* {t("AboutUs.loginMessage")} */}
                {t('It_is_run')}
              </p>
              {/* <Button
                text={t(`header-footer-menu.Sign in`)}
                classes=""
                size="lg"
                onClick={() => handleLoginPopup(true)}
              /> */}
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsHeroText);
