import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../customHooks/useIsMobile';

// Images
import { ReactComponent as Facebook } from '@assets/pictures/svgs/facebook-active.svg';
import { ReactComponent as Instagram } from '@assets/pictures/svgs/instagram-active.svg';
import { ReactComponent as Twitter } from '@assets/pictures/svgs/twitter-active.svg';
import { ReactComponent as Tiktok } from '@assets/pictures/svgs/tiktok-logo-4501.svg';
import logoSample from '@assets/pictures/logo-colored.png';
import { ReactComponent as Send } from '@assets/pictures/svgs/send.svg';
import { ReactComponent as HomeIcon } from '@assets/pictures/svgs/home_new.svg';
import { ReactComponent as TicketIcon } from '@assets/pictures/svgs/ticket_new.svg';
import { ReactComponent as ProfileIcon } from '@assets/pictures/svgs/profile__new.svg';
import { Container, Row, div, Dropdown, Modal } from 'react-bootstrap';
import MobileBottomMenu from '@components/partials/MobileBottomMenu.jsx';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { CityService, LanguageService } from '@apiService/tokenService';
import { globalConfigContext } from '@context/GlobalConfigContext';
import i18n from '@plugins/i18n';
import { motion } from 'framer-motion';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { titleCase } from '@helper/formatting';
import { setShowLoginPopup } from '@store/auth/authActions';
import nozolyLogo from '@assets/pictures/nozoly_logo.png';

function Footer({ t }) {
  const dispatch = useDispatch();

  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser } = loginDetails;
  const isMobile = useIsMobile();
  const history = useHistory();
  const newsLetterEmail = useRef();
  const subscribeMsg = useRef();
  const organization = useSelector((state) => state.movies.organizationDetails);

  const social_media = useSelector((state) => state.homepage.social_media_cms);
  const footer_cms = useSelector((state) => state.homepage.footer_cms);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);
  const [showBottomMenu, setShowBottomMenu] = useState(true);

  const { org_logo_url, org_mycinema_logo, org_name } = organization;
  const { pathname } = useLocation();

  const handleLoginPopup = (toShow) => {
    dispatch(setShowLoginPopup(toShow));
  };

  useEffect(() => {
    // console.log("social_media", social_media);
  }, [social_media]);

  useEffect(() => {}, [pathname]);

  const currentTab = (path) => {
    let tab = '';
    if (path.startsWith('/home') || path.startsWith('/')) tab = 'homeTab';
    if (path.startsWith('/schedules')) tab = 'scheduleTab';
    if (path.startsWith('/guest-user-profile') || path.startsWith('/user-profile'))
      tab = 'userTab';

    return tab;
  };

  async function subscribe() {
    const payload = {
      text: newsLetterEmail.current.value,
    };
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        payload.text,
      )
    ) {
      const response = await GenericService.GetNewsLetter(payload);
      if (response.status === 200 && response.data.status === true) {
        subscribeMsg.current.innerText = t('Thank you for subscribing');
        subscribeMsg.current.className = 'text-success';
      } else {
        subscribeMsg.current.innerText = t('You have already subscribed!');
        subscribeMsg.current.className = 'text-warning';
      }
      console.log(response);
    } else {
      subscribeMsg.current.innerText = t('Please enter a valid email');
      subscribeMsg.current.className = 'text-danger';
    }
  }

  const showItem = (item) => {
    if (!CMSLOADER) {
      if (footer_cms && footer_cms.length > 0) {
        if (footer_cms[0][item] == 'Y') {
          return true;
        } else if (footer_cms[0][item] == 'N') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (social_media && social_media.length > 0) {
        if (social_media[0][item]) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const openUrl = (url) => {
    console.log('i am here', url);
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  if (
    pathname.startsWith('/schedules') ||
    pathname.startsWith('/fnb') ||
    pathname.startsWith('/create-show') ||
    pathname.startsWith('/screening-type') ||
    pathname.startsWith('/seat-layout') ||
    pathname.startsWith('/payment') ||
    pathname.includes('booking') ||
    pathname.startsWith('/final-ticket') ||
    pathname.startsWith('/unpaid-ticket') ||
    pathname.startsWith('/gift-card-details') ||
    pathname.startsWith('/gift-card-payment') ||
    pathname.startsWith('/gift-card-topup')

    // ||
    // (pathname.startsWith("/movie-details") &&
    //   pathname.split("/").length >= 5 &&
    //   pathname.split("/")[4] !== "2")
  ) {
    return null;
  }

  return (
    <footer className="footer_new">
      {isMobile ? (
        // Mobile Design
        <>
          {showBottomMenu && (
            <div className="container-fluid">
              <section className="row">
                <article className="col-12">
                  <div className="footer_new_icon_links">
                    <HomeIcon
                      className={currentTab(pathname) === 'homeTab' ? 'active' : ''}
                      onClick={() => {
                        history.push('/');
                      }}
                    />
                    <TicketIcon
                      className={
                        currentTab(pathname) === 'scheduleTab' ? 'active' : ''
                      }
                      onClick={() => {
                        history.push('/schedules');
                      }}
                    />
                    {/*{!loggedInUser && guestUser && (
                      <p onClick={() => history.push("/guest-user-profile")}>
                        {t("My Profile")}
                      </p>
                    )}
                    {loggedInUser && (
                      <p onClick={() => history.push("/user-profile")}>
                        {t("My Profile")}
                      </p>
                    )}*/}
                    <ProfileIcon
                      className={currentTab(pathname) === 'userTab' ? 'active' : ''}
                      onClick={() => {
                        if (!loggedInUser && guestUser) {
                          history.push('/guest-user-profile');
                        } else if (loggedInUser) {
                          history.push('/user-profile');
                        } else {
                          handleLoginPopup(true);
                        }
                      }}
                    />
                  </div>
                </article>
              </section>
            </div>
          )}
        </>
      ) : (
        // Desktop/Tablet Design
        <div className="container-fluid">
          <section className="row">
            <article className="col-12">
              <div className="footer_new_items">
                {/* <figure className="footer_new_logo">
               {showItem("Logo") ? (
                      <Link className="mb-0" to="/">
                        <img
                          src={org_mycinema_logo || org_logo_url}
                          alt={org_name}
                          // width="115px"
                          width="200px"
                          className=""
                        />
                      </Link>
                    ) : (
                      <div
                        className="dummy-image"
                        style={{
                          width: "100px",
                        }}
                      ></div>
                    )}
        </figure> */}
                {showItem('Logo') && (
                  <figure
                    className="footer_new_logo"
                    onClick={() => history.push('/')}
                  >
                    <img
                      src={org_mycinema_logo || org_logo_url}
                      alt={org_name}
                      className=""
                    />
                    {/* <img src={nozolyLogo} alt="nozoly logo" className="" /> */}
                  </figure>
                )}
                <figure className="sm_icons">
                  {showItem('Social Links') && (
                    <div className="footer-sm-links py-0">
                      {isItemActive('facebok_link') && (
                        <Facebook
                          onClick={() => openUrl(social_media[0]?.facebok_link)}
                        />
                      )}
                      {isItemActive('insta_link') && (
                        <Instagram
                          onClick={() => openUrl(social_media[0]?.insta_link)}
                        />
                      )}
                      {isItemActive('twitter_link') && (
                        <Twitter
                          onClick={() => openUrl(social_media[0]?.twitter_link)}
                        />
                      )}
                      <a
                        href="https://www.tiktok.com/@petrocinema?_t=8Xb10QbLUuH&_r=1"
                        target="_blank"
                        className=""
                      >
                        <Tiktok />
                      </a>
                    </div>
                  )}
                  {/* <Facebook />
          <Instagram/> */}
                </figure>
                <h3>{t('GetLatestMovieUpdate')}</h3>
                <label className="footer_new_newsletter d-none">
                  <input
                    type="email"
                    name=""
                    id=""
                    className=""
                    placeholder={t('Enter your Email ID')}
                    ref={newsLetterEmail}
                  />
                  <Send
                    onClick={(e) => {
                      e.preventDefault();
                      subscribe();
                      newsLetterEmail.current.value = '';
                    }}
                  />
                </label>
                <p ref={subscribeMsg} />

                <ul className="list-inline">
                  <li className="list-inline-item">
                    {showItem('About US') && (
                      <Link to="/about-us" className="footer_new_link">
                        {t('About_us_pet')}
                      </Link>
                    )}
                  </li>
                  <li className="list-inline-item">
                    {showItem('Contact US') && (
                      <Link to="/contact-us" className="footer_new_link">
                        {t('contact_us_pet')}
                      </Link>
                    )}
                  </li>
                  {/* <li className="list-inline-item">
                {showItem("FAQs") && (
                        <Link to="/faqs" className="footer_new_link">{t("common.FAQs")}</Link>
                      )}
                </li> */}
                  <li className="list-inline-item">
                    {showItem('Terms n Conditions') && (
                      <Link to="/tnc" className="footer_new_link">
                        {/* {t("common.Terms and Conditions")} */}
                        {t('tnc_footer_pet')}
                      </Link>
                    )}
                  </li>
                  <li className="list-inline-item">
                    {showItem('Privacy Policy') && (
                      <Link to="/privacy-policy" className="footer_new_link">
                        {/* {t("common.Privacy Policy")} */}
                        {t('pp_footer_pet')}
                      </Link>
                    )}
                  </li>
                </ul>
                <h6>&copy; {t('All Rights Reserved')}</h6>
              </div>
            </article>
          </section>
        </div>
      )}
    </footer>
  );
}
export default withNamespaces()(Footer);
