import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLangSpecificAttribute } from '@helper/languages';
import useIsMobile from '../customHooks/useIsMobile';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

// Images
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
// import classImg from "@assets/pictures/classs_img.png";
import classImg from '@assets/pictures/Opening_hours_new_class.jpeg';
const OpeningHours = ({ t }) => {
  const isMobile = useIsMobile();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  /* let openings = [
    {
      openingsFor: [
        'Opening Hours',
      ],
      hours: [
        'Monday to Thursday - 11:30-21:00',
        'Friday and Saturday - 11:30-23:00',
        'Sunday - 13:00-21:00',
      ]
    },
    {
      openingsFor: [
        'Opening Hours for',
        'Screening'
      ],
      hours: [
        'Monday to Thursday - 09:00-21:00',
        'Friday - 09:00-01:30',
        'Saturday - 10:30-01:30',
        'Sunday - 12:00-21:00',
      ]
    }
  ] */

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb
          firstHalf={`${t('more_pet')} >`}
          secondHalf={t('Opening_Hours_pet')}
        />
        <CenteredTitle firstHalf={t('Opening_Hours_pet') + ':'} secondHalf={''} />
      </div>

      <div className="container-fluid opening_hours">
        <section className="row">
          <article className="col-4 mb-4 mb-md-0">
            <figure className="opening_hours_pattern">
              <img src={classImg} alt="Pattern" />
            </figure>
          </article>

          <article className="col-md-8">
            <section
              // flex-md-row
              className="d-flex flex-column flex-wrap"
              style={{ justifyContent: 'space-between' }}
            >
              <div
                className="pr-md-3 d-flex justify-content-between flex-wrap"
                style={{ gap: '10px' }}
              >
                <h3 className="d-none">
                  <p>
                    {/*{t('Opening Hours')}*/}
                    <span style={{ color: '#e6007e' }}>
                      {t('Opening hours cinema')}
                    </span>
                  </p>
                  <p></p>
                </h3>

                {/* <p>{t(`OH.Monday-Thursday`)}</p>
                <p style={{ color: "#e6007e" }}>11:30 - 22:00</p>

                <p>{t(`OH.Friday-Saturday`)}</p>
                <p style={{ color: "#e6007e" }}>11:30 - 24:00</p>

                <p>{t(`OH.Sunday`)}</p>
                <p style={{ color: "#e6007e" }}>13:30 - 22:00</p> */}
                <div
                  className="d-flex justify-content-between align-items-center custom_font_size_op"
                  style={{
                    direction: selected_language.lang_id == 3 ? 'rtl' : 'ltr',
                    textAlign: selected_language.lang_id == 3 ? 'right' : '',
                  }}
                >
                  <div>
                    <p
                      style={{
                        marginLeft: selected_language.lang_id == 3 ? '10px' : '',
                      }}
                    >
                      {t('Morning_shift_pet')}
                    </p>
                  </div>
                  <div className="timing_op_btn">
                    <p>
                      {t('Six_6')}
                      {t('A_M_pet')} - {t('Two_2')}
                      {t('P_M_pet')}
                    </p>
                  </div>
                </div>

                <div
                  className="d-flex justify-content-between align-items-center custom_font_size_op"
                  style={{
                    direction: selected_language.lang_id == 3 ? 'rtl' : 'ltr',
                    textAlign: selected_language.lang_id == 3 ? 'right' : '',
                  }}
                >
                  <div>
                    <p
                      style={{
                        marginLeft: selected_language.lang_id == 3 ? '10px' : '',
                      }}
                    >
                      {t('Night_shift_pet')}
                    </p>
                  </div>
                  <div className="timing_op_btn">
                    <p
                      style={{
                        marginLeft: selected_language.lang_id == 3 ? '10px' : '',
                      }}
                    >
                      {t('Four_4')}
                      {t('P_M_pet')} - {t('Two_2')}
                      {t('A_M_pet')}
                    </p>
                  </div>
                </div>
              </div>

              {/*Private Cinema */}
              <div
                className="pl-md-3 d-none"
                style={{ margin: isMobile ? '15px 0px 30px 0px' : undefined }}
              >
                <h3>
                  <p style={{ color: '#C09E3F' }}>
                    {t('Private Cinema Bookings')}{' '}
                    {/*<span className="white_text">{t('Show.Booking')}</span>*/}{' '}
                  </p>
                </h3>
                {/*<p>{t(`Daily 09 00 to 22 00`)}</p>
                <p>{t(`(film start) possible`)}</p>*/}

                <p style={{ whiteSpace: 'nowrap' }}>{t(`OH.Daily`)}</p>
                <p style={{ color: '#C09E3F' }}>
                  09:00 - 22:00 {t(`OH.film start`)}
                </p>
              </div>

              {/*Edie Wine Bar */}
              <div className="mb-4 mb-md-0 pl-md-3 d-none">
                <h3>
                  <p>
                    {/*<span  className="white_text text-nowrap">{t('Edie Weinstube')} </span>*/}{' '}
                    <span color="#e6007e">{t(`Edi's wine bar`)}</span>
                  </p>
                </h3>

                <p style={{ whiteSpace: 'nowrap' }}>{t(`OH.Monday-Thursday`)}</p>
                <p style={{ color: '#e6007e' }}>15:00 - 24:00</p>

                <p>{t(`OH.Friday-Saturday`)}</p>
                <p style={{ color: '#e6007e' }}>15:00 - 02:00</p>

                <p>{t(`OH.Sunday`)}</p>
                <p style={{ color: '#e6007e' }}>14:00 - 21:00</p>
              </div>
            </section>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(OpeningHours);
