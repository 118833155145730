import Api from './Api';

export default {
  GetVouchersList(payload) {
    return Api().post(`/api/external/offers-vouchers`, payload);
  },
  ValidateVoucherCode(payload) {
    return Api().post(`/api/external/verify-voucher`, payload);
  },
  ValidateVoucherCodeV2(payload) {
    return Api().post(`/api/external/v2/verify-voucher`, payload);
  },
  AddCustomerToSegment(payload) {
    return Api().post('/api/segments', payload);
  },
};
