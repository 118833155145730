import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFnbItems,
  onSelectFnb,
  clearFnbState,
  saveFnbItems,
  fetchFnbCategories,
} from '@store/foodAndBeverages/foodAndBeveragesActions';
import useIsTablet from '@src/customHooks/useIsTablet';
import {
  fnbItemsWithSearchSelector,
  fnbItemCategoriesSelector,
} from '@store/foodAndBeverages/foodAndBeveragesSelectors';
import popcorn from '@assets/pictures/popcorn.png';
import fnbSample from '@assets/pictures/fnb-sample.jpg';
import { useParams } from 'react-router';
import Spinner from './partials/Spinner';
import { ReactComponent as ArrowBack } from '@assets/pictures/svgs/arrow-back.svg';
import { ReactComponent as ArrowBackActive } from '@assets/pictures/svgs/arrow-back-active.svg';
import { ReactComponent as DropDownArrow } from '@assets/pictures/svgs/dropdown-arrow.svg';
import { ReactComponent as NewDropDownArrow } from '@assets/pictures/svgs/newdroparrow.svg';
import { motion } from 'framer-motion';
import useIsMobile from '../customHooks/useIsMobile';
import searchIcon from '@assets/pictures/svgs/search-fnb-new.svg';
import close from '@assets/pictures/svgs/close.svg';
import { currencyFormatter } from '@helper/currencyFormatter';
import { getLangSpecificAttribute } from '@helper/languages';
/*import 'antd/dist/antd.css';*/
import { Row, Col } from 'antd';
/*import { ArrowLeftOutlined } from "@ant-design/icons";*/
import _ from 'lodash';
import { seatTypesSelector } from '@store/movies/movieSelector';
import {
  fetchReservationDetails,
  fetchReservationDetailsBrij,
  fetchStripePublisherKey,
  fetchActivePaymentModes,
} from '@store/movies/movieActions';
import { setReservationID } from '@store/seatLayout/seatLayoutActions';
import '../assets/css/style.css';
import { ReactComponent as LeftArrow } from '@assets/pictures/svgs/slider-arrow-left.svg';
import GoBack from '@components/partials/GoBack';

import { ReactComponent as FnbAdd } from '@assets/pictures/svgs/fnb-add.svg';
import { ReactComponent as FnbAddGold } from '@assets/pictures/svgs/fnb-add-gold.svg';
import { ReactComponent as FnbRemove } from '@assets/pictures/svgs/fnb-remove.svg';

const Fnb = ({ t }) => {
  const {
    ss_id,
    screen_id,
    md_id,
    mycinema_ss_d_id,
    booking_type_id,
    fnb_item_id,
  } = useParams();
  let category_id = ss_id && screen_id && md_id ? 2 : 1;
  const [back, setBack] = useState(false);
  const [itemType, setItemType] = useState('all');
  const isMobile = useIsMobile();
  const [footerAccordion, setFooterAccordion] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [compulsoryItem, setCompulsoryItem] = useState([]);
  const [compulsoryItemAdded, setCompulsoryItemAdded] = useState(false);

  const history = useHistory();
  const isTablet = useIsTablet();
  const dispatch = useDispatch();
  const reservation_id = useSelector((state) => state.seatLayout.reservation_id);
  // const fnbItems = useSelector((state) => state.fnb.fnb_items);
  const fnbItemsLoader = useSelector((state) => state.fnb.fnb_loader);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  // const fnbCategories = useSelector((state) =>
  //   fnbItemCategoriesSelector(state)
  // );
  const fnbCategories = useSelector((state) => state.fnb.fnb_categories);
  const fnbItemsWithSearch = useSelector((state) =>
    fnbItemsWithSearchSelector(state, search, selectedCategory),
  );

  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);
  const all_fnb_items = useSelector((state) => state?.fnb?.fnb_items);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);

  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);

  const selected_language = useSelector((state) => state.movies.selected_language);

  const { lang_name, iso_2, lang_id } = selected_language;

  useEffect(() => {
    if (scheduleDetails) {
      dispatch(
        fetchFnbItems({ cinema_id: scheduleDetails.cinema_id, reservation_id }),
      );
      dispatch(fetchFnbCategories());
    }
  }, [scheduleDetails]);

  function footerAccordionHandler() {
    if (window.innerWidth < 768) {
      setFooterAccordion(true);
    } else if (window.innerWidth >= 768) {
      setFooterAccordion(false);
    }
  }

  window.addEventListener('resize', footerAccordionHandler);
  useEffect(footerAccordionHandler, []);

  const goToSeatLayout = useCallback(() => {
    if (mycinema_ss_d_id && booking_type_id) {
      history.go(-1);

      // history.replace(
      //   `/create-show-seat-layout/${mycinema_ss_d_id}/${booking_type_id}`
      // );
    } else if (ss_id && screen_id && md_id) {
      history.go(-1);

      // history.replace(`/seat-layout/${ss_id}/${screen_id}/${md_id}`);
    }
  }, [mycinema_ss_d_id, booking_type_id, ss_id, screen_id, md_id]);

  const proceedToPayment = (skip = false) => {
    if (skip && !compulsoryItemAdded) {
      dispatch({ type: 'CLEAR_FNB_STATE' });
      history.push(`/payment/${reservation_id}/${category_id}`);
      return;
    }

    // perform reservation here
    if (selectedFnbItems?.length === 0) {
      // history.push(`/payment/${reservation_id}/${category_id}`);
      dispatch(saveFnbItems(category_id)).then((status) => {
        if (status) {
          history.push(`/payment/${reservation_id}/${category_id}`);
        }
      });
    } else {
      dispatch(saveFnbItems(category_id)).then((status) => {
        if (status) {
          history.push(`/payment/${reservation_id}/${category_id}`);
        }
      });
    }
  };

  if (!reservation_id) {
    goToSeatLayout();
  }

  function percentage(percent, total) {
    return (percent / 100) * total;
  }

  useEffect(() => {
    category_id == 1
      ? dispatch(fetchReservationDetails({ reservation_id, category_id }))
      : dispatch(fetchReservationDetailsBrij({ reservation_id, category_id }));
    window.scrollTo(0, 0);
    dispatch(fetchStripePublisherKey());
    dispatch(fetchActivePaymentModes(2));
    dispatch(setReservationID(reservation_id));
  }, []);

  const booking_type = useSelector((state) => state.movies.booking_type);
  const reservationDetails = useSelector(
    (state) => state.movies.reservationDetails,
  );
  const reservationDetailsLoading = useSelector(
    (state) => state.movies.reservationDetailsLoading,
  );

  const add_ons_array = useSelector(
    (state) => state?.movies?.reservationDetails?.add_ons_array,
  );
  const is_internet_handling_enable = useSelector(
    (state) => state?.seatLayout?.is_internet_handling_enable,
  );
  const seatLayout = useSelector((state) => state?.seatLayout);

  const discount = useSelector((state) => state.promotions.voucher_discount);
  let private_rate_card_detail = reservationDetails?.private_rate_card_detail ?? [];

  let ticket_count =
    private_rate_card_detail.length > 0
      ? private_rate_card_detail[0].customSeatCount
      : null;

  const movieBottomRef = useRef();
  const movieInfoRef = useRef();
  useEffect(() => {
    const setMovieBottomWidth = () => {
      const movieBottom = movieBottomRef.current;
      const movieInfo = movieInfoRef.current;
      if (movieBottom && movieInfo) {
        movieBottom.style.width = movieInfo.offsetWidth + 'px';
      }
    };
    setMovieBottomWidth();
    window.addEventListener('resize', setMovieBottomWidth);
    return () => window.removeEventListener('resize', setMovieBottomWidth);
  }, []);

  const seatTypes = useSelector((state) => seatTypesSelector(state));

  useEffect(() => {
    console.log({ scheduleDetails });
  }, [scheduleDetails]);
  useEffect(() => {
    console.log({ reservationDetails });
    console.log({ add_ons_array });
    console.log({ is_internet_handling_enable, seatLayout });
  }, [reservationDetails, add_ons_array, is_internet_handling_enable, seatLayout]);

  //handling compulsory fnb Item
  useEffect(() => {
    if (compulsoryItem && compulsoryItem.length == 1 && !compulsoryItemAdded) {
      let seatCount = scheduleDetails?.selectedSeats?.length || 0;

      for (let i = 0; i < seatCount; i++) {
        dispatch(onSelectFnb('add', compulsoryItem[0]));

        setCompulsoryItemAdded(true);
      }
    }
  }, [compulsoryItem, scheduleDetails]);

  useEffect(() => {
    if (!!fnb_item_id) {
      let item = (all_fnb_items || []).filter(
        (item) => item?.item_id == fnb_item_id,
      );
      setCompulsoryItem(item);
    }
  }, [fnb_item_id, all_fnb_items, scheduleDetails]);

  function getRateCardAddonsTotal(add_ons_array = []) {
    if (!add_ons_array) return 0;
    if (add_ons_array?.length === 0) return 0;

    return add_ons_array?.[0]?.BoxOfficeFinal?.TotalAddons ?? 0;
  }
  function getInternetHandlingFees(
    category_id,
    is_internet_handling_enable,
    scheduleDetails,
    reservationDetails,
  ) {
    if (!is_internet_handling_enable) return 0;

    if (category_id == 2 && is_internet_handling_enable)
      return scheduleDetails?.internet_handling_fee ?? 0;
    else {
      if (reservationDetails?.selectedSeats?.length > 0)
        return (
          reservationDetails?.selectedSeats?.length *
          reservationDetails?.selectedSeats?.[0]?.internet_handling_fee
        );
      else return 0;
    }
  }

  if (fnbItemsLoader || reservationDetailsLoading)
    return (
      <div className="row d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );

  return (
    <div className="mob_fnb_over_flow" style={{ overflow: 'hidden' }}>
      <div className="new_fnb_grid">
        <section
          className={`left_main-fnb  ${
            !isMobile && !isTablet ? 'col-md-4 col-xl-3' : ''
          }`}
        >
          {!isMobile && (
            <div className="left_fnb_grid" ref={movieInfoRef}>
              {/* Sidebar Section*/}
              <div className="sidebar_fnb fnb-order-details">
                <p className={`title ${category_id == 1 && ' text-golden '}`}>
                  {t('My F&B ORDER')}
                </p>
                <section className="fnb_scroll">
                  {selectedFnbItems &&
                    selectedFnbItems.length > 0 &&
                    selectedFnbItems.map((selectedItem) => (
                      <article
                        className="fnb-item-details"
                        key={selectedItem.item_id}
                      >
                        <p>
                          {getLangSpecificAttribute(
                            selectedItem?.FnbContent,
                            lang_id,
                            'item_lang_name',
                          ) || selectedItem.item_name}
                          {selectedItem.Quantity > 1 && (
                            <>
                              &nbsp;
                              <span>x{selectedItem.Quantity}</span>
                              <p>
                                {t('Single Item')} - {curr_code}{' '}
                                {currencyFormatter(selectedItem.fp_total_amount)}
                              </p>
                            </>
                          )}
                        </p>
                        <p>
                          {curr_code} {currencyFormatter(selectedItem.total_price)}
                        </p>
                      </article>
                    ))}
                  {selectedFnbItems.length === 0 && (
                    <section
                      className="d-flex"
                      style={{
                        justifyContent: 'space-between',
                        fontSize: '12px',
                        paddingRight: '8px',
                      }}
                    >
                      {t('No Items Selected')}
                      <span>{curr_code} 00.00</span>
                    </section>
                  )}
                </section>
                <article className="fnb-total">
                  <p>{t('Total')}</p>
                  <p>
                    {curr_code} {currencyFormatter(fnbPrice.total_price)}
                  </p>
                </article>
                {!isMobile && (
                  <button
                    className={`btn-main-new ${
                      category_id == 1 && ' golden_btn_pvt '
                    } ${selectedFnbItems?.length == 0 && ' inactive '}`}
                    onClick={() => {
                      proceedToPayment();
                    }}
                  >
                    {selectedFnbItems.length > 0
                      ? t('Proceed')
                      : t(
                          // "Skip"
                          'Proceed',
                        )}
                  </button>
                )}
              </div>
              {/* Booking Section*/}
              <div className="title_n_value">
                <h6 className={`${category_id == 1 && ' text-golden '}`}>
                  {t('Booking Summary').toUpperCase()}
                </h6>
                <article className="payment_details_rows">
                  {seatTypes?.length > 0 &&
                    seatTypes?.map((seatType, seatTypeIndex) => (
                      <React.Fragment key={seatTypeIndex}>
                        {seatType?.seats?.length > 0 &&
                          seatType?.seats?.map((rcg_child, index2) => (
                            <section className="good" key={index2}>
                              <p className="left_value">
                                <span className="ml-0">
                                  (x{rcg_child.no_of_seats} {t('Seats')})
                                </span>
                                &nbsp;
                                <>{seatType.sst_seat_type}</>
                                <p>
                                  <span className="">
                                    {rcg_child?.ticket_type_translations?.[
                                      iso_2?.toLowerCase()
                                    ] || rcg_child.tt_name}
                                  </span>
                                </p>
                              </p>

                              <p className="right_value">
                                {curr_code}{' '}
                                {currencyFormatter(rcg_child?.total_price)}
                              </p>
                            </section>
                          ))}
                      </React.Fragment>
                    ))}
                  {scheduleDetails?.booking_type_id == 3 ? (
                    <>
                      <section className="good">
                        <div className="left_value">
                          {scheduleDetails?.screen_name}
                        </div>
                        <div className="right_value">
                          {!!ticket_count
                            ? `(x${ticket_count}):`
                            : `(x${scheduleDetails?.ticket_count}):`}
                        </div>
                      </section>
                      <section className="">
                        <div className="left_value">
                          {scheduleDetails?.booking_type_name}
                        </div>
                        <div className="right_value">
                          {curr_code}{' '}
                          {currencyFormatter(reservationDetails?.amount)}
                        </div>
                      </section>
                    </>
                  ) : (
                    <>
                      {/* <section className="">
                <div className="left_value">{scheduleDetails?.seat_name}</div>
                <div className="right_value">{curr_code}{" "}{currencyFormatter(reservationDetails?.amount)}</div>
                    </section> */}
                    </>
                  )}
                </article>
                {/* Gross addons on rate card */}
                {add_ons_array?.[0]?.gross_amount_addons?.length > 0 && (
                  <div className="payment_details_rows" id="grossAddonsDetails">
                    <section className="good">
                      <div className="left_value">{t('Addons')}</div>
                      <div className="right_value"></div>
                    </section>

                    {add_ons_array?.[0]?.gross_amount_addons?.map((item) => (
                      <section className="good">
                        <div className="left_value">
                          {item.quantity && <span>(x{item.quantity}) </span>}
                          {t(item.addons_name) || item.addons_name}
                        </div>
                        <div className="right_value">
                          {curr_code} {currencyFormatter(item?.final_price)}
                        </div>
                      </section>
                    ))}

                    <section className="good">
                      <div className="left_value">
                        {t('Addons')} {t('Total')}
                      </div>
                      <div className="right_value">
                        {curr_code}{' '}
                        {currencyFormatter(getRateCardAddonsTotal(add_ons_array))}
                      </div>
                    </section>
                  </div>
                )}

                {/* {selectedFnbItems && selectedFnbItems.length > 0 && (
            <article className="grey-title-lg mb-2">
              {t("Concessions")}
            </article>
          )} */}

                {isMobile && selectedFnbItems && selectedFnbItems.length > 0 && (
                  <div className="payment_details_rows">
                    {selectedFnbItems.map((item, index) => (
                      <section className="good" key={index}>
                        <div className="left_value">
                          {item.Quantity && <span>(x{item.Quantity}) </span>}

                          {getLangSpecificAttribute(
                            item?.FnbContent,
                            lang_id,
                            'item_lang_name',
                          ) || item.item_name}
                        </div>
                        <div className="right_value">
                          {curr_code} {currencyFormatter(item.fp_total_amount)}
                        </div>
                      </section>
                    ))}
                  </div>
                )}

                {discount && (
                  <div className="payment_details_rows text-success">
                    <section className="good">
                      <div className="left_value"> {t('Discount')}: </div>
                      <div className="right_value">
                        {' '}
                        - {curr_code} {currencyFormatter(discount)}
                      </div>
                    </section>
                  </div>
                )}
                {getInternetHandlingFees(
                  category_id,
                  is_internet_handling_enable,
                  scheduleDetails,
                  reservationDetails,
                ) > 0 && (
                  <div className="payment_details_rows">
                    <section className="good">
                      <div className="left_value">
                        {' '}
                        {t('Internet Handling Fee')}
                      </div>
                      <div className="right_value">
                        {' '}
                        {curr_code}{' '}
                        {currencyFormatter(
                          getInternetHandlingFees(
                            category_id,
                            is_internet_handling_enable,
                            scheduleDetails,
                            reservationDetails,
                          ),
                        )}
                      </div>
                    </section>
                  </div>
                )}
              </div>
              <article ref={movieInfoRef}>
                <div
                  className={`movie-bottom ${
                    category_id == 1 && ' movie-bottom-gold '
                  }}`}
                >
                  <section className="">
                    <div className="">
                      <h5 className="text_fnb_bold">{t('Grand Total')}</h5>
                      <h4 className="text_fnb_bold">
                        {curr_code}{' '}
                        {currencyFormatter(
                          reservationDetails?.amount +
                            fnbPrice.total_price -
                            (discount ? discount : 0) +
                            getRateCardAddonsTotal(add_ons_array) +
                            getInternetHandlingFees(
                              category_id,
                              is_internet_handling_enable,
                              scheduleDetails,
                              reservationDetails,
                            ),
                        )}
                      </h4>
                    </div>
                    <h6>
                      {t('(Incl. of 2.5% VAT - ')} {curr_code}{' '}
                      {currencyFormatter(
                        _.round(
                          percentage(
                            2.5,
                            reservationDetails?.amount +
                              fnbPrice.total_price -
                              (discount ? discount : 0) +
                              getRateCardAddonsTotal(add_ons_array) +
                              getInternetHandlingFees(
                                category_id,
                                is_internet_handling_enable,
                                scheduleDetails,
                                reservationDetails,
                              ),
                          ),
                          4,
                        ),
                      )}{' '}
                      {')'}
                    </h6>
                  </section>
                </div>
              </article>
            </div>
          )}
        </section>
        {/*Right Section */}
        <div className="right_fnb_grid col-xl-9 col-md-7 col-lg-8">
          {/* Progress Bar Section*/}
          <div className="progress_fnb inner_pages_header_fnb">
            <div
              className={`${category_id == 1 ? 'gold_go_back' : 'go_back'}`}
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
              onClick={goToSeatLayout}
            >
              <LeftArrow />
            </div>
            <div className="custom_breadcrumbs_fnb">
              <div className="steps_new">
                <span className=" bor_new1">
                  {category_id == 1
                    ? t('Private Cinema Booking')
                    : `${t('Now Showing')}`}
                </span>

                <span
                  className={`mx-3 ${category_id == 1 ? 'bor_new_pvt' : 'bor_new'}`}
                >
                  {t('Food & Beverages')}
                </span>
                <span className="bor_new1">{t('Checkout')}</span>
              </div>

              {/*<span onClick={()=>history.push("/now-showing")}>
                  Now Showing</span> <span>
              Now Showing Booking</span> */}
            </div>

            <div />
          </div>
          {/* Main Section*/}
          <div className="king" style={{ marginLeft: '0px' }}>
            <div className="food">
              <h4 className="bev">{t('Select Food and Beverage')}</h4>
              {isMobile ? (
                <button
                  className={`check_out_mob ${
                    category_id == 1 && ' golden_btn_pvt '
                  }`}
                  onClick={() => {
                    proceedToPayment(true);
                  }}
                >
                  {t('Skip')}
                </button>
              ) : (
                <button
                  className={`check_out ${category_id == 1 && ' golden_btn_pvt '}`}
                  onClick={() => {
                    proceedToPayment(true);
                  }}
                >
                  {t('Skip to checkout ?')}
                </button>
              )}
            </div>
            <div className="sch">
              <label className="fnb-search">
                <img
                  src={searchIcon}
                  alt="search"
                  onClick={(e) => e.target.nextElementSibling.focus()}
                />
                <input
                  type="text"
                  placeholder={t('Search for concessions')}
                  // autoFocus
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{
                    border:
                      category_id == 1
                        ? '1px solid var(--yellow-shade)'
                        : '1px solid var(--primary)',
                  }}
                />
                <img
                  src={close}
                  alt="close"
                  className={`${search ? 'show' : ''}`}
                  onClick={() => setSearch('')}
                />
              </label>
              <section className="ments">
                <button
                  className={`fnb-category-btn ${
                    selectedCategory === '' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedCategory('')}
                >
                  {t('All')}
                </button>
                {fnbCategories &&
                  fnbCategories.length > 0 &&
                  fnbCategories.map((category, categoryIndex) => (
                    <button
                      key={categoryIndex}
                      className={`fnb-category-btn  ${
                        selectedCategory === category ? 'selected' : ''
                      }`}
                      onClick={() => setSelectedCategory(category)}
                    >
                      {getLangSpecificAttribute(
                        category?.FnbCategoryContent,
                        lang_id,
                        'category_lang_name',
                      ) || category.fc_category}
                    </button>
                  ))}
              </section>
            </div>
          </div>
          {/* Product Section*/}
          <div className="fnb_card_main">
            {fnbItemsWithSearch && fnbItemsWithSearch.length > 0 ? (
              <>
                {fnbItemsWithSearch.map((item, index) => (
                  <FnbCard
                    scheduleDetails={scheduleDetails}
                    fnb_item_id={fnb_item_id}
                    key={index}
                    item={item}
                    t={t}
                    selectedFnbItem={(selectedFnbItems || []).filter(
                      (sf) => item?.item_id == sf?.item_id,
                    )}
                  />
                ))}
              </>
            ) : (
              <div className="row justify-content-center align-items-center no_item">
                {t('No Items Found')}
              </div>
            )}
          </div>
        </div>
      </div>

      {/*New Fnb Design */}

      {/*Fnb Old design */}
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <section className="inner_pages_header_fnb" style={{ display: 'none' }}>
            <GoBack
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
              onClick={goToSeatLayout}
            />
            <div className="custom_breadcrumbs_fnb">
              <div className="steps_new">
                <span className=" bor_new1">
                  {category_id == 1
                    ? t('Private Cinema Booking')
                    : t('Now Showing Booking')}
                </span>

                <span
                  className={`mx-3 ${category_id == 1 ? 'bor_new_pvt' : 'bor_new'}`}
                >
                  {t('Food & Beverages')}
                </span>
                <span className="bor_new1">{t('Checkout')}</span>
              </div>

              {/*<span onClick={()=>history.push("/now-showing")}>
                  Now Showing</span> <span>
              Now Showing Booking</span> */}
            </div>

            <div />
          </section>

          {/*<div className="gain">

            {/*<button
              className="btn-main-back  mt-2"
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
              onClick={goToSeatLayout}
            >*/}
          {/* <img
                src={back ? arrowBackActive : arrowBack}
                className="mr3"
                alt=""
              /> */}
          {/*<ArrowLeftOutlined /><span style={{ color: "white", fontSize: "14px", paddingLeft: "10px" }}>Back</span>*/}

          {/* Back
            {/*</button>
              onClick={goToSeatLayout}
            />
            <div className="name_new" style={{ borderBottom: "1px solid #2f2e31" }}>
              <span style={{ color: "#2f2e31" }} className="px-4">
                {t("Now Showing")?.toUpperCase()}
              </span>
              <span style={{ color: "white", borderBottom: "2px solid #e8308a" }} className="px-4">
                {t('Food & Beverages')?.toUpperCase()}
              </span>
              <span style={{ color: "#2f2e31" }} className="px-4">
                {t("Checkout")?.toUpperCase()}
              </span>
            </div>
          </div>


*/}
        </Col>
      </Row>
      <Col span={24}>
        <Row gutter={[32, 32]}>
          {/*<Col span={6} className="v_b">

            <section
              className={`fnb-order-details ${isMobile ? "mb-2" : "mb-3"} col-xl-3`}
              style={{ marginLeft: "18px" }}>
              <p className={`title ${category_id == 1 && ' text-golden '}`}>{t("My F&B ORDER")}</p>
              <section className="fnb_scroll">
                {selectedFnbItems &&
                  selectedFnbItems.length > 0 &&
                  selectedFnbItems.map((selectedItem) => (
                    <article
                      className="fnb-item-details"
                      key={selectedItem.item_id}
                    >
                      <p>
                        {getLangSpecificAttribute(
                          selectedItem?.FnbContent,
                          lang_id,
                          "item_lang_name"
                        ) || selectedItem.item_name}
                        {selectedItem.Quantity > 1 && (
                          <>
                            &nbsp;
                            <span>x{selectedItem.Quantity}</span>
                            <p>
                              {t("Single Item")} - {curr_code}{" "}
                              {currencyFormatter(
                                selectedItem.fp_total_amount
                              )}
                            </p>
                          </>
                        )}
                      </p>
                      <p>
                        {curr_code}{" "}
                        {currencyFormatter(selectedItem.total_price)}
                      </p>
                    </article>
                  ))}
                {selectedFnbItems.length === 0 && (
                  <section className="d-flex" style={{justifyContent:"space-between",fontSize:"12px",paddingRight:"8px"}}>
                    {t("No Items Selected")}
                    <span>{curr_code}{" "}00.00</span>
                  </section>
                )}
              </section>
              <article className="fnb-total">
                <p>{t("Total")}</p>
                <p>
                  {curr_code} {currencyFormatter(fnbPrice.total_price)}
                </p>
              </article>
              {!isMobile && (
                <button
                  className={`btn-main-new ${category_id == 1 && ' golden_btn_pvt '}`}
                  onClick={() => {
                    proceedToPayment();
                  }}
                >
                  {selectedFnbItems.length > 0 ? t("Proceed") : t("Skip")}
                </button>
              )}


            </section>
                </Col>*/}

          {/*<Col span={18}>
            <div className="king col-xl-9">

              <div className="food">
                <h4 className="bev">{t("Select Food and Beverage")}
                </h4>
                {
                  isMobile
                    ? <button className={`check_out_mob mx-auto ${category_id == 1 && ' golden_btn_pvt '}`} onClick={() => { proceedToPayment() }}>{t("Skip")}</button>
                    : <button className={`check_out ${category_id == 1 && ' golden_btn_pvt '}`} onClick={() => { proceedToPayment() }}>{t("Skip to checkout ?")}</button>
                }

              </div>
              <div className="sch">
                <label className="fnb-search">
                  <img
                    src={searchIcon}
                    alt="search"
                    onClick={(e) => e.target.nextElementSibling.focus()}
                  />
                  <input
                    type="text"
                    placeholder={t("Search for concessions")}
                    // autoFocus
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{
                      border: category_id == 1 ? '1px solid var(--yellow-shade)' : '1px solid var(--primary)'
                    }}
                  />
                  <img
                    src={close}
                    alt="close"
                    className={`${search ? "show" : ""}`}
                    onClick={() => setSearch("")}
                  />
                </label>
                <section className="ments">
                  <button
                    className={`fnb-category-btn ${
                      selectedCategory === "" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedCategory("")}
                  >
                    {t("All")}
                  </button>
                  {fnbCategories &&
                    fnbCategories.length > 0 &&
                    fnbCategories.map((category, categoryIndex) => (
                      <button
                        key={categoryIndex}
                        className={`fnb-category-btn  ${
                          selectedCategory === category ? "selected" : ""
                        }`}
                        onClick={() => setSelectedCategory(category)}
                      >
                        {getLangSpecificAttribute(
                          category?.FnbCategoryContent,
                          lang_id,
                          "category_lang_name"
                        ) || category.fc_category}
                      </button>

                    ))}

                </section>
              </div>
            </div>
                        </Col>*/}
        </Row>
      </Col>

      <div
        className="container-fluid container-xl header-space "
        style={{ paddingLeft: '0px' }}
      >
        {/*<p className="mx-auto big-heading text-md-center for-prev-step">
          {/*<div className="prev-step">
            <button
              className="btn-main mx-auto mt-2"
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
              onClick={goToSeatLayout}
            >
              {/* <img
                src={back ? arrowBackActive : arrowBack}
                className="mr3"
                alt=""
              /> }
                              {/*{back ? <ArrowBackActive/> : <ArrowBack/>}}

              {/* Back }
            </button>
            </div>}
          {t("Concessions")}
        </p>*/}

        {/* <section className="row justify-content-center for-prev-step">
          <div className="prev-step">
            <button
              className="btn-main mx-auto mt-3 mt-xl-4"
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
            >
              <img
                src={back ? arrowBackActive : arrowBack}
                className="mr3"
                alt=""
              /> */}
        {/* Back */}
        {/* </button>
          </div> */}

        {/* <div className="col-sm-9 mx-auto">
            <div className="steps">
              <div className={`step ticket complete`}></div>
              <div className={`step screen complete`}></div>
              <div className={`step seat complete`}></div>
            </div>
          </div> */}
        {/* </div> */}
        {/* </section> */}

        {/*<section className="row mb-3">
          <article className="col-10 col-md-6 col-lg-5 col-xl-3">
            <label className="fnb-search">
              <img
                src={searchIcon}
                alt="search"
                onClick={(e) => e.target.nextElementSibling.focus()}
              />
              <input
                type="text"
                placeholder={t("Search for concessions")}
                autoFocus
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <img
                src={close}
                alt="close"
                className={`${search ? "show" : ""}`}
                onClick={() => setSearch("")}
              />
            </label>
          </article>
        </section>*/}

        <section className="row">
          <article className="col-12">
            {/* <section className="row align-items-center">
              <article className="col-lg-6 order-1 order-md-0"> */}
            {/*<div className="inline-scrollable">
              <section>
                <button
                  className={`fnb-category-btn ${
                    selectedCategory === "" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedCategory("")}
                >
                  {t("All")}
                </button>
                {fnbCategories &&
                  fnbCategories.length > 0 &&
                  fnbCategories.map((category, categoryIndex) => (
                    <button
                      key={categoryIndex}
                      className={`fnb-category-btn ${
                        selectedCategory === category ? "selected" : ""
                      }`}
                      onClick={() => setSelectedCategory(category)}
                    >
                      {getLangSpecificAttribute(
                        category?.FnbCategoryContent,
                        lang_id,
                        "category_lang_name"
                      ) || category.fc_category}
                    </button>
                  ))}
              </section>
                      </div>*/}
            {/* </article> */}
            {/* <article className="col-lg-6 mb-3 mb-md-0">
                <label className="fnb-search">
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => e.target.nextElementSibling.focus()}
                  />
                  <input
                    type="search"
                    placeholder="Search for concessions"
                    autoFocus
                  />
                </label>
              </article> */}
            {/* </section> */}
          </article>
        </section>

        <section className="row fnb-row">
          <article className="col-md-5 col-xl-3 fnb-mobile-bar">
            {!footerAccordion && isMobile && (
              <>
                <div className="title_n_value">
                  <h6 className={`${category_id == 1 && ' text-golden '}`}>
                    {t('Booking Summary').toUpperCase()}
                  </h6>
                  <article className="payment_details_rows">
                    {seatTypes?.length > 0 &&
                      seatTypes?.map((seatType, seatTypeIndex) => (
                        <React.Fragment key={seatTypeIndex}>
                          {seatType?.seats?.length > 0 &&
                            seatType?.seats?.map((rcg_child, index2) => (
                              <section className="good" key={index2}>
                                <p className="left_value">
                                  <span className="ml-0">
                                    (x{rcg_child.no_of_seats} {t('Seats')})
                                  </span>
                                  &nbsp;
                                  <>{seatType.sst_seat_type}</>
                                  <span className="">
                                    {rcg_child?.ticket_type_translations?.[
                                      iso_2?.toLowerCase()
                                    ] || rcg_child.tt_name}
                                  </span>
                                </p>

                                <p className="right_value">
                                  {curr_code}{' '}
                                  {currencyFormatter(rcg_child?.total_price)}
                                </p>
                              </section>
                            ))}
                        </React.Fragment>
                      ))}
                    {scheduleDetails?.booking_type_id == 3 ? (
                      <>
                        <section className="good">
                          <div className="left_value">
                            {scheduleDetails?.screen_name}
                          </div>
                          <div className="right_value">
                            {!!ticket_count
                              ? `(x${ticket_count}):`
                              : `(x${scheduleDetails?.ticket_count}):`}
                          </div>
                        </section>
                        <section className="">
                          <div className="left_value">
                            {scheduleDetails?.booking_type_name}
                          </div>
                          <div className="right_value">
                            {curr_code}{' '}
                            {currencyFormatter(reservationDetails?.amount)}
                          </div>
                        </section>
                      </>
                    ) : (
                      <>
                        {/* <section className="">
                <div className="left_value">{scheduleDetails?.seat_name}</div>
                <div className="right_value">{curr_code}{" "}{currencyFormatter(reservationDetails?.amount)}</div>
                    </section> */}
                      </>
                    )}
                  </article>
                  {/* Gross addons on rate card */}
                  {add_ons_array?.[0]?.gross_amount_addons?.length > 0 && (
                    <div className="payment_details_rows" id="grossAddonsDetails">
                      <section className="good">
                        <div className="left_value">{t('Addons')}</div>
                        <div className="right_value"></div>
                      </section>

                      {add_ons_array?.[0]?.gross_amount_addons?.map((item) => (
                        <section className="good">
                          <div className="left_value">
                            {item.quantity && <span>(x{item.quantity}) </span>}
                            {t(item.addons_name) || item.addons_name}
                          </div>
                          <div className="right_value">
                            {curr_code} {currencyFormatter(item?.final_price)}
                          </div>
                        </section>
                      ))}

                      <section className="good">
                        <div className="left_value">
                          {t('Addons')} {t('Total')}
                        </div>
                        <div className="right_value">
                          {curr_code}{' '}
                          {currencyFormatter(getRateCardAddonsTotal(add_ons_array))}
                        </div>
                      </section>
                    </div>
                  )}
                  {/* {selectedFnbItems && selectedFnbItems.length > 0 && (
            <article className="grey-title-lg mb-2">
              {t("Concessions")}
            </article>
          )} */}

                  {isMobile && selectedFnbItems && selectedFnbItems.length > 0 && (
                    <div className="payment_details_rows">
                      {selectedFnbItems.map((item, index) => (
                        <section className="good" key={index}>
                          <div className="left_value">
                            {item.Quantity && <span>(x{item.Quantity}) </span>}

                            {getLangSpecificAttribute(
                              item?.FnbContent,
                              lang_id,
                              'item_lang_name',
                            ) || item.item_name}
                          </div>
                          <div className="right_value fnbValue">
                            {curr_code}{' '}
                            {currencyFormatter(
                              item.Quantity * item.fp_total_amount,
                            )}
                          </div>
                        </section>
                      ))}
                    </div>
                  )}

                  {discount && (
                    <div className="payment_details_rows text-success">
                      <section className="good">
                        <div className="left_value"> {t('Discount')}: </div>
                        <div className="right_value">
                          {' '}
                          - {curr_code} {currencyFormatter(discount)}
                        </div>
                      </section>
                    </div>
                  )}
                  {getInternetHandlingFees(
                    category_id,
                    is_internet_handling_enable,
                    scheduleDetails,
                    reservationDetails,
                  ) > 0 && (
                    <div className="payment_details_rows">
                      <section className="good">
                        <div className="left_value">
                          {' '}
                          {t('Internet Handling Fee')}
                        </div>
                        <div className="right_value">
                          {' '}
                          {curr_code}{' '}
                          {currencyFormatter(
                            getInternetHandlingFees(
                              category_id,
                              is_internet_handling_enable,
                              scheduleDetails,
                              reservationDetails,
                            ),
                          )}
                        </div>
                      </section>
                    </div>
                  )}

                  {/*<div className="payment_details_rows payment_details_total">
                 <section className="">
                <h5>{t("Grand Total")}

              </h5>
              <h4 className="">
                  {curr_code}{" "}
            {currencyFormatter(
              reservationDetails?.amount +
              fnbPrice.total_price -
              (discount ? discount : 0)
            )}
                </h4>



            </section>
            <h6>
                {t("(Incl. of 2.5% VAT - ")} {curr_code}{" "}
            {currencyFormatter(
              _.round(
                percentage(
                  2.5,
                  reservationDetails?.amount +
                  fnbPrice.total_price -
                  (discount ? discount : 0)
                ),
                4
              )
            )}{" "}
                  {")"}
          </h6>
                </div>*/}
                </div>

                <div className="movie_info_scroll_space" />

                <article
                  className={`movie-bottom ${
                    category_id == 1 && ' movie-bottom-gold '
                  }}`}
                  ref={movieBottomRef}
                  style={{ display: 'none' }}
                >
                  <section className="">
                    <div>
                      <h5 className="text_fnb_bold">
                        <b>{t('Grand Total')}</b>
                      </h5>
                      <h4 className="text_fnb_bold">
                        {curr_code}{' '}
                        {currencyFormatter(
                          reservationDetails?.amount +
                            fnbPrice.total_price -
                            (discount ? discount : 0) +
                            getRateCardAddonsTotal(add_ons_array) +
                            getInternetHandlingFees(
                              category_id,
                              is_internet_handling_enable,
                              scheduleDetails,
                              reservationDetails,
                            ),
                        )}
                      </h4>
                    </div>
                    <h6>
                      {t('(Incl. of 2.5% VAT - ')} {curr_code}{' '}
                      {currencyFormatter(
                        _.round(
                          percentage(
                            2.5,
                            reservationDetails?.amount +
                              fnbPrice.total_price -
                              (discount ? discount : 0) +
                              getRateCardAddonsTotal(add_ons_array) +
                              getInternetHandlingFees(
                                category_id,
                                is_internet_handling_enable,
                                scheduleDetails,
                                reservationDetails,
                              ),
                          ),
                          4,
                        ),
                      )}{' '}
                      {')'}
                    </h6>
                  </section>

                  {/* <div className="img-top">
          <img
            src={circles}
            alt="Arrow Down"
          />
        </div> */}
                </article>

                {/*<section
                  className={`fnb-order-details ${isMobile ? "mb-2" : "mb-3"}`}
                >
                  <p className="title">{t("Your order details")}</p>
                  {selectedFnbItems &&
                    selectedFnbItems.length > 0 &&
                    selectedFnbItems.map((selectedItem) => (
                      <article
                        className="fnb-item-details"
                        key={selectedItem.item_id}
                      >
                        <p>
                          {getLangSpecificAttribute(
                            selectedItem?.FnbContent,
                            lang_id,
                            "item_lang_name"
                          ) || selectedItem.item_name}
                          {selectedItem.Quantity > 1 && (
                            <>
                              &nbsp;
                              <span>x{selectedItem.Quantity}</span>
                              <p>
                                {t("Single Item")} - {curr_code}{" "}
                                {currencyFormatter(
                                  selectedItem.fp_total_amount
                                )}
                              </p>
                            </>
                          )}
                        </p>
                        <p>
                          {curr_code}{" "}
                          {currencyFormatter(selectedItem.total_price)}
                        </p>
                      </article>
                    ))}
                  {selectedFnbItems.length === 0 && (
                    <section className="d-flex justify-content-center align-items-center">
                      {t("No Items Selected")}
                    </section>
                  )}
                  <article className="fnb-total">
                    <p>{t("Total")}</p>
                    <p>
                      {curr_code} {currencyFormatter(fnbPrice.total_price)}
                    </p>
                  </article>
                  </section>
                {!isMobile && (
                  <button
                    className="btn-main mx-auto"
                    onClick={() => {
                      proceedToPayment();
                    }}
                  >
                    {selectedFnbItems.length > 0 ? t("Confirm") : t("Skip")}
                  </button>
                )}*/}
              </>
            )}
            {isMobile && (
              <article className={`pay_now ${category_id == 1 && ' back_gold '}`}>
                {/*row align-items-center black*/}
                <div className="col-6 total_fnb">
                  <p style={{ fontSize: '14px' }}>
                    <b>{t('Total Payable')}</b>
                  </p>
                  <p style={{ fontSize: '9px' }}>
                    {t('(Incl. of 2.5% VAT - ')} {curr_code}{' '}
                    {currencyFormatter(
                      _.round(
                        percentage(
                          2.5,
                          reservationDetails?.amount +
                            fnbPrice.total_price -
                            (discount ? discount : 0) +
                            getRateCardAddonsTotal(add_ons_array) +
                            getInternetHandlingFees(
                              category_id,
                              is_internet_handling_enable,
                              scheduleDetails,
                              reservationDetails,
                            ),
                        ),
                        4,
                      ),
                    )}{' '}
                    {')'}
                  </p>
                  {/*<p className="fnb-item-count">
                    {selectedFnbItems.length} {t("Items selected")}
            </p>*/}
                  <p className="">
                    {curr_code}{' '}
                    {currencyFormatter(
                      reservationDetails?.amount +
                        fnbPrice.total_price -
                        (discount ? discount : 0) +
                        getRateCardAddonsTotal(add_ons_array) +
                        getInternetHandlingFees(
                          category_id,
                          is_internet_handling_enable,
                          scheduleDetails,
                          reservationDetails,
                        ),
                    )}
                  </p>
                </div>
                <div className="col-6 col-xs-8  for-ft">
                  <button
                    className={`btn-main footer ${
                      category_id == 1 ? 'gold_hov text-golden ' : ``
                    }`}
                    onClick={() => {
                      proceedToPayment();
                    }}
                  >
                    {selectedFnbItems.length > 0 ? t('Pay Now') : t('Skip')}
                  </button>
                  <div
                    className={`footer-toggle ${
                      category_id == 1 && ' golden-footer-fig '
                    }`}
                  >
                    <figure
                      className={`${!footerAccordion ? 'ft-rotate' : ''}`}
                      onClick={() => {
                        footerAccordion
                          ? setFooterAccordion(false)
                          : setFooterAccordion(true);
                      }}
                    >
                      <NewDropDownArrow />
                    </figure>
                  </div>
                </div>
              </article>
            )}
          </article>
          <article
            className={`col-md-7 col-xl-9 ${false && !isMobile ? 'fnb-items' : ''}`}
          >
            {fnbItemsLoader && (
              <div className="row d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
            )}
            {/* {!fnbItemsLoader &&
              (fnbItemsWithSearch && fnbItemsWithSearch.length > 0 ? (
                <div className="row boing" style={{ paddingLeft: "50px" }}>
                  {fnbItemsWithSearch.map((item, index) => (
                    <FnbCard
                      scheduleDetails={scheduleDetails}
                      fnb_item_id={fnb_item_id}
                      key={index}
                      item={item}
                      t={t}
                      selectedFnbItem={(selectedFnbItems || []).filter(
                        (sf) => item?.item_id == sf?.item_id
                      )}
                    />
                  ))}
                </div>
              ) : (
                <div className="row justify-content-center align-items-center">
                  {t("No Items Found")}
                </div>
              ))} */}
          </article>
        </section>
      </div>
    </div>
  );
};

function FnbCard({ item, t, selectedFnbItem, fnb_item_id, scheduleDetails }) {
  const { ss_id, screen_id, md_id, mycinema_ss_d_id, booking_type_id } =
    useParams();
  let category_id = ss_id && screen_id && md_id ? 2 : 1;

  const isMobile = useIsMobile();
  const [index, setIndex] = useState(0);

  const dispatch = useDispatch();

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  const onSelectFnbItem = (type, item) => {
    dispatch(onSelectFnb(type, item));
  };

  const selected_language = useSelector((state) => state.movies.selected_language);

  const { lang_name, iso_2, lang_id } = selected_language;

  const getCount = (selectedItm, itm) => {
    if (!!selectedItm) return selectedItm?.Quantity;
    else return itm?.Quantity;
  };

  return (
    <motion.div
      className=""
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="fnb-card">
        <figure className="img-wrapper">
          <img src={item.item_image_url} alt={item.item_name} />
        </figure>
        <article
          className={
            isMobile
              ? 'd-flex flex-column align-items-center justify-content-between'
              : 'fnb_item_wrapper'
          }
        >
          <div
            className="fnb-content-row"
            style={{
              display: !isMobile ? 'flex' : undefined,
              height: !isMobile ? '50%' : undefined,
            }}
          >
            <p className="">
              {getLangSpecificAttribute(
                item?.FnbContent,
                lang_id,
                'item_lang_name',
              ) || item.item_name}
            </p>
            <p className="">
              {curr_code} {currencyFormatter(item.fp_total_amount)}
            </p>
          </div>

          {isMobile ? (
            <p className="descrip_space">
              {getLangSpecificAttribute(
                item?.FnbContent,
                lang_id,
                'item_description',
              ) || item?.item_description}
            </p>
          ) : (
            <></>
          )}
          <div className="fnb-content-row">
            {!getCount(selectedFnbItem?.[0], item) ||
            getCount(selectedFnbItem?.[0], item) == 0 ? (
              <aside className="aside_content">
                {!isMobile ? (
                  <p className="descrip_space">
                    {getLangSpecificAttribute(
                      item?.FnbContent,
                      lang_id,
                      'item_description',
                    ) || item?.item_description}
                  </p>
                ) : (
                  <></>
                )}
                <button
                  className={`btn-main-fnb ${
                    category_id == 1 && ' golden_btn_pvt '
                  }`}
                  /*style={{ marginRight: "5px" }}*/
                  onClick={() => onSelectFnbItem('add', item)}
                >
                  {t('Add')}
                </button>
              </aside>
            ) : (
              <aside className="modifier-btn">
                {!isMobile ? (
                  <p className="descrip_space">
                    {getLangSpecificAttribute(
                      item?.FnbContent,
                      lang_id,
                      'item_description',
                    ) || item?.item_description}
                  </p>
                ) : (
                  <></>
                )}
                <div
                  className="mod_btn"
                  style={{ justifyContent: isMobile ? 'center' : undefined }}
                >
                  <span
                    className={
                      !getCount(selectedFnbItem?.[0], item) ? 'inactive' : ''
                    }
                    onClick={() => {
                      if (
                        !!fnb_item_id &&
                        item.item_id == fnb_item_id &&
                        getCount(selectedFnbItem?.[0], item) <=
                          scheduleDetails?.selectedSeats?.length
                      ) {
                        return;
                      }

                      onSelectFnbItem('sub', item);
                    }}
                  >
                    <FnbRemove style={{ transform: 'scale(1.4)' }} />
                  </span>
                  <span
                    className={`mx-2 ${
                      !getCount(selectedFnbItem?.[0], item) ? 'inactive' : ''
                    }`}
                  >
                    {getCount(selectedFnbItem?.[0], item) !== 0 &&
                    getCount(selectedFnbItem?.[0], item)
                      ? getCount(selectedFnbItem?.[0], item)
                      : '0'}
                  </span>
                  <span
                    className="fnb_add_btn"
                    onClick={() => onSelectFnbItem('add', item)}
                  >
                    {category_id == 1 ? (
                      <FnbAddGold style={{ transform: 'scale(1.4)' }} />
                    ) : (
                      <FnbAdd style={{ transform: 'scale(1.4)' }} />
                    )}
                  </span>
                </div>
              </aside>
            )}
          </div>
        </article>
        {/* <aside className="">
          <p>25% off</p>
          <p>CHF 48</p>
        </aside> */}
      </div>
    </motion.div>
  );
}

export default withNamespaces()(Fnb);
