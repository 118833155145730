import React, { useState, useEffect, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../customHooks/useIsMobile';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Components
import CashCardInfoPayment from './helper/CashCardInfoPayment';
import GoBack from '@components/partials/GoBack';
import { Accordion, Card } from 'react-bootstrap';
import Spinner from '../partials/Spinner';
import CountryCodeDropdown from '@components/partials/CountryCodeDropdown';
import LoginOtp from '@components/partials/LoginOtp';
import ForgotPasswordOtp from '@components/partials/ForgotPasswordOtp';

// Images
import showPasswordIcon from '@assets/pictures/svgs/show-password.svg';
import hidePasswordIcon from '@assets/pictures/svgs/hide-password.svg';
import giftCardImg from '@assets/pictures/gift-card.png';
import {
  cinemaBookSeats,
  fetchActivePaymentModes,
  fetchStripePublisherKey,
  makeSaferPayPaymentForCashCard,
  makeStripePaymentForCashCard,
} from '../../store/movies/movieActions';
import { fetchReserveBuyCashCard } from '../../store/cashCard/cashCardActions';
import {
  forgotPassword,
  guestRegister,
  login as loginAction,
  loginViaOtp,
  numberRegex,
  register,
  resetTimer,
  startTimer,
} from '../../store/auth/authActions';
import { seatTypesSelector } from '../../store/movies/movieSelector';

const CashCardPayment = ({ t }) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();

  const { reservation_id } = useParams();

  const [open, setOpen] = useState([1, 0, 0, 0, 0, 0, 0, 0]);
  // const [defaultTab, setDefaultTab] = useState("0");
  const [otp, setOtp] = useState([]);

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  const [GUEST_CHECKOUT, setGUEST_CHECKOUT] = useState(
    (globalOptions.find((g) => g.go_key === `GUEST_CHECKOUT`)?.go_value || 'N') ===
      'Y',
  );
  const [memberBool, setMemberBool] = useState('login');
  const [switchTo, setSwitchTo] = useState('guestCheckout');
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [loadingOtp, setLoadingOtp] = useState(false);

  const booking_type = useSelector((state) => state.movies.booking_type);
  const reservationDetails = useSelector(
    (state) => state.movies.reservationDetails,
  );
  const minutes = useSelector((state) => state.movies.timer.minutes);
  const seconds = useSelector((state) => state.movies.timer.seconds);
  const timerCompleted = useSelector((state) => state.movies.timerCompleted);
  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const seatTypes = useSelector((state) => seatTypesSelector(state));

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, loginError, guestUser } =
    loginDetails;

  const [registerLoading, setRegisterLoading] = useState(false);
  const [type, setType] = useState(null);
  const [registerConditionsCheckError, setRegisterConditionsCheckError] =
    useState(false);
  const handleChangeOtp = (value) => {
    setOtp(value);
  };

  const getStepWithOffset = (key, incrementBy1 = false) => {
    let k = parseInt(key);

    if (incrementBy1) k = k + 1;
    if (typeof key === 'string') k = String(k);

    return k;
  };

  useEffect(() => {
    setGUEST_CHECKOUT(
      (globalOptions.find((g) => g.go_key === `GUEST_CHECKOUT`)?.go_value ||
        'N') === 'Y',
    );
    console.log({
      GUEST_CHECKOUT_FLAG: globalOptions.find((g) => g.go_key === `GUEST_CHECKOUT`)
        ?.go_value,
      GUEST_CHECKOUT,
    });
  }, [globalOptions]);

  const toggleHandle = (key) => {
    key = parseInt(key);

    if (GUEST_CHECKOUT) key = key + 1;

    setDefaultTab(`${key ? key : 0}`);
    setOpen((oldState) => {
      let openCopy = Array(8).fill(0);
      openCopy[key] = !oldState[key];
      return openCopy;
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchReserveBuyCashCard(reservation_id));
    dispatch(fetchStripePublisherKey());
    dispatch(fetchActivePaymentModes(2));
    // dispatch(setReservationID(reservation_id));
  }, [reservation_id]);

  useEffect(() => {
    if (timerCompleted === true) {
      dispatch({
        type: `SET_TIMEOUT_ERROR`,
        payload: { message: `Your session has expired`, show: true },
      });
    }
  }, [timerCompleted]);
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const [confirmDetails, setConfirmDetails] = useState(false);
  const [countryMobCodeGuest, setCountryMobCodeGuest] = useState(null);

  const onConfirmPayment = () => {
    // category_id == 1
    //   ? dispatch(
    //       cinemaBookSeats({
    //         reservation_id,
    //         cinema_id: scheduleDetails.cinema_id,
    //         customer_id: loggedInUser?.cust_id,
    //         email,
    //       })
    //     )
    //   : dispatch(
    //       bookSeats({bookSeats
    //         reservation_id,
    //         cinema_id: scheduleDetails.cinema_id,
    //         customer_id: loggedInUser?.cust_id,
    //         email,
    //       })
    //     );

    if (isMobile) {
      toggleHandle(3);
    } else {
      toggleHandle(2);
    }
  };
  const onClickLoginConfirmDetails = (e, guestUser) => {
    if (e) {
      e.preventDefault();
    }

    // if (!loggedInConditionsCheck) {
    //   setLoginConditionsCheckError(true);
    //   return;
    // }
    setLoginSubmitted(true);
    if (!guestUser && (mobile === '' || email === '')) {
      return;
    } else if (guestUser && email === '') {
      return;
    }
    console.log('in confirm condition');
    setIsConfirmed(true);

    if (guestUser) {
      console.log({
        guest_country_code_og: guestUser.guest_country_code,
        guest_country_code: countryMobCodeGuest,
      });
      let payload = {
        guest_email: guestUser.guest_email,
        guest_first_name: !!guestFirstName
          ? guestFirstName
          : guestUser.guest_first_name,
        guest_last_name: !!guestLastName
          ? guestLastName
          : guestUser.guest_last_name,
        guest_mobile: !!mobile ? mobile : guestUser.guest_mobile,
        guest_country_code: !!countryMobCodeGuest
          ? countryMobCodeGuest
          : guestUser.guest_country_code,
        callback: () => {},
      };
      dispatch(guestRegister(payload)).catch(console.error);
    }

    let openNew = open;
    openNew[0] = false;
    setOpen(openNew);
    setTimeout(() => {
      setConfirmDetails(true);
      isMobile ? toggleHandle(2) : toggleHandle(1);
    }, 100);
  };
  const onClickRegisterConfirmDetails = (e) => {
    e.preventDefault();
    setRegisterConditionsCheckError(false);
    if (!registerConditionsCheck) {
      setSubmitted(true);
      setRegisterConditionsCheckError(true);
      return;
    }

    console.log(memberBool === 'guestCheckout', {
      memberBool,
    });

    if (memberBool === 'guestCheckout') {
      setSubmitted(true);
      if (emailNotMatched) return;
      if (!registerEmail) return;
      if (!lastName) return;
      if (!firstName) return;

      setRegisterLoading(true);
      let payload = {
        guest_email: registerEmail,
        guest_first_name: firstName,
        guest_last_name: lastName,
        guest_mobile: registerMobile,
        guest_country_code: countryMobCode,
        callback: setRegisterLoading,
      };

      dispatch(guestRegister(payload)).then((r) => {
        setTimeout(() => {
          toggleHandle(isMobile ? 2 : 1);
        }, 10);
      });
      return;
    }

    if (
      registerEmail === '' ||
      registerMobile === '' ||
      firstName === '' ||
      lastName === '' ||
      password === '' ||
      !registerConditionsCheck
      //  || !selectedDate
    ) {
      return;
    }
    setRegisterLoading(true);
    let payload = {
      email: registerEmail,
      first_name: firstName,
      last_name: lastName,
      password,
      phone_no: registerMobile,
      country_id: countryId,
      callback: setRegisterLoading,
    };
    if (selectedDate) payload.dob = moment(selectedDate).format('YYYY-MM-DD');

    dispatch(register(payload));
  };

  const onErrorHandling = () => {
    if (loginError === 'Password is incorrect') {
      onClickForgotPassword();
    } else {
      setMemberBool('register');
      setLoginSubmitted(false);
    }
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setType('email');
    setLoginSubmitted(true);
    let count = 0;

    if (
      (!isNumber && loginField === '') ||
      (isNumber && (loginField === '' || !countryId))
    ) {
      count++;
    }

    if (loginPassword === '') {
      count++;
    }

    if (count > 0) {
      setLoading(false);
      return;
    }

    dispatch(
      loginAction({
        loginField,
        password: loginPassword,
        callback: setLoading,
        countryId,
      }),
    );
  };

  // const reservation_id = useSelector((state) => state.seatLayout.reservation_id);

  const [email, setEmail] = useState(``);
  const [mobile, setMobile] = useState(``);
  const [loggedInConditionsCheck, setLoggedInConditionsCheck] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const emailInput = React.useRef(null);
  const mobileInput = React.useRef(null);

  const [guestFirstName, setGuestFirstName] = useState(``);
  const [guestLastName, setGuestLastName] = useState(``);
  const guestFirstNameInput = React.useRef(null);
  const guestLastNameInput = React.useRef(null);

  useEffect(() => {
    if (loggedInUser) {
      emailInput.current.focus();
    }
  }, [email]);
  useEffect(() => {
    if (loggedInUser) {
      mobileInput.current.focus();
    }
  }, [mobile]);
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);
  const [loading, setLoading] = useState(false);
  const selected_seats = useSelector((state) => state.seatLayout.seats_selected);
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const isUnpaidBooking = useSelector((state) => state.movies.isUnpaidBooking);
  const netAmount = useSelector((state) => state.movies.netAmount);
  const [registerEmail, setRegisterEmail] = useState(``);
  const [confirmregisterEmail, setconfirmRegisterEmail] = useState(``);
  const [emailNotMatched, setEmailNotMatched] = useState(false);
  const [registerMobile, setRegisterMobile] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [defaultTab, setDefaultTab] = useState('0');
  const [lastName, setLastName] = useState(``);
  const [password, setPassword] = useState(``);
  const [registerConditionsCheck, setRegisterConditionsCheck] = useState(false);
  const [loginConditionsCheckError, setLoginConditionsCheckError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loginSubmitted, setLoginSubmitted] = useState(false);
  const [loginSubmittedViaOTP, setLoginSubmittedViaOTP] = useState(false);
  const [loginPassword, setLoginPassword] = useState('');
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [allowResendLogin, setAllowResendLogin] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [allowForgotPasswordResend, setAllowForgotPasswordResend] = useState(false);
  const [fpField, setFpField] = useState('');

  const [loginField, setLoginField] = useState('');
  const [isNumber, setisNumber] = useState(true);

  useEffect(() => {
    let ifNumber = numberRegex(loginField);
    if (!ifNumber) setCountryId(null);

    setisNumber(ifNumber);
  }, [loginField]);

  const [dobError, setDobError] = useState(false);
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [ogDate, setOgDate] = useState(null);

  const state = useSelector((state) => state);
  const {
    auth: { loginOtpError },
  } = state;

  const isLoginViaOTPClicked = useRef(false);

  const onClickLoginViaOTP = () => {
    setType('otp');
    if (isLoginViaOTPClicked.current === true) {
      return;
    }

    setLoginSubmittedViaOTP(true);
    let count = 0;
    if (loginField === ``) {
      count++;
    }

    if (count > 0) {
      return;
    }
    setLoadingOtp(true);
    // setSubmittedviaOtp(false);
    isLoginViaOTPClicked.current = true;

    setTimeout(() => {
      isLoginViaOTPClicked.current = false;
    }, 5 * 1000);

    dispatch(
      loginViaOtp({
        loginField,
        callback: setLoadingOtp,
        successCallback: () => {
          setMemberBool('otp-login');
          dispatch(startTimer());
          setAllowResendLogin(false);
          setTimeout(() => setAllowResendLogin(true), 30000);
        },
      }),
    );
  };

  const onClickForgotPassword = () => {
    setOtpPopup(true);
    setMemberBool('forgot-password');
  };

  const onClickResendLogin = () => {
    if (allowResendLogin) {
      setAllowResendLogin(false);
      dispatch(
        loginViaOtp({
          loginField,
          callback: () => {},
          successCallback: () => {
            dispatch(resetTimer());
            dispatch(startTimer());
            setAllowResendLogin(false);
            setInterval(() => setAllowResendLogin(true), 30000);
          },
        }),
      );
    }
  };

  const onClickResend = () => {
    if (allowForgotPasswordResend) {
      setAllowForgotPasswordResend(false);
      dispatch(
        forgotPassword({
          field: fpField,
          callback: () => {},
          successCallback: () => {
            setAllowForgotPasswordResend(false);
            dispatch(resetTimer());
            dispatch(startTimer());
            setInterval(() => setAllowForgotPasswordResend(true), 30000);
          },
        }),
      );
    }
  };

  const emailRegisterInput = React.useRef(null);
  const confirmemailRegisterInput = React.useRef(null);
  const mobileRegisterInput = React.useRef(null);
  const fNameRegisterInput = React.useRef(null);
  const lnameRegisterInput = React.useRef(null);
  const passwordRef = React.useRef(null);

  useEffect(() => {
    const existingGuestUserId = localStorage.getItem(`guest_user_id`);
    if (!existingGuestUserId && !loggedInUser) {
      const guest_user_id = Math.floor(100000000 + Math.random() * 900000000);
      localStorage.setItem(`guest_user_id`, guest_user_id);
    }
  }, []);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      emailRegisterInput.current.focus();
    }
  }, [registerEmail]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      confirmemailRegisterInput.current.focus();
    }
  }, [confirmregisterEmail]);

  useEffect(() => {
    if (!loggedInUser && !guestUser && registerEmail !== confirmregisterEmail) {
      setEmailNotMatched(true);
    } else {
      setEmailNotMatched(false);
    }
  }, [registerEmail, confirmregisterEmail]);

  useEffect(() => {
    console.log({ guestUser });
  }, [guestUser]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      mobileRegisterInput.current.focus();
    }
  }, [registerMobile]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      fNameRegisterInput.current.focus();
    }
  }, [firstName]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      lnameRegisterInput.current.focus();
    }
  }, [lastName]);
  useEffect(() => {
    if (loggedInUser) {
      setEmail(loggedInUser?.email || loggedInUser?.cust_email);
      setMobile(loggedInUser?.phone_no || loggedInUser?.cust_mobile);
    } else if (guestUser) {
      setEmail(guestUser.guest_email);
      if (!!guestUser.guest_mobile) setMobile(guestUser.guest_mobile);
      if (!!guestUser.guest_first_name)
        setGuestFirstName(guestUser.guest_first_name);
      if (!!guestUser.guest_last_name) setGuestLastName(guestUser.guest_last_name);
    }
  }, [loggedInUser, guestUser]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      passwordRef.current.focus();
    }
  }, [password]);

  useEffect(() => {
    if (GUEST_CHECKOUT && (loggedInUser || guestUser)) {
      if (!isMobile) {
        setDefaultTab('1');
        setOpen([0, true, 0, 0, 0, 0, 0, 0]);
      } else {
        setDefaultTab('2');
        setOpen([0, 0, true, 0, 0, 0, 0, 0]);
      }
    }
  }, [loggedInUser, GUEST_CHECKOUT, isMobile]);

  const timeoutError = useSelector((state) => state.movies.timeoutError);

  const cashCardReservation = useSelector((s) => s.cashCard.cashCardReservation);
  const cashCardReservationLoading = useSelector(
    (s) => s.cashCard.cashCardReservationLoading,
  );

  return (
    <section className="container-fluid px-3 payment-page">
      <div className="row page-content">
        {!isMobile && (
          <div className="col-md-4 col-xl-3 payment-booking-details pl-md-0">
            {cashCardReservationLoading ? (
              <div className="row">
                <div className="col-12 text-center">
                  <Spinner />
                </div>
              </div>
            ) : (
              <CashCardInfoPayment />
            )}
          </div>
        )}

        <div className="col-md-8 col-xl-9 userDetailsAccordian pr-0 pl-0 pl-md-3">
          {/* NEW BACK BUTTON */}
          {!isMobile ? (
            <div className="inner_pages_header">
              <GoBack />
              <div className="custom_breadcrumbs">
                <span
                  className="bor_new1"
                  onClick={() => history.push('/cash-cards')}
                >
                  {t('Cash Cards')?.toUpperCase()}
                </span>
                <span className="bor_new1 mx-3">
                  {t('Cash Cards')?.toUpperCase()} {t('Details')?.toUpperCase()}
                </span>
                <span className="bor_new">{t('Checkout')?.toUpperCase()}</span>
              </div>
              <div className="fs-14  pl-3 pl-md-0 pr-3">
                {!timeoutError?.show ? (
                  <p>
                    {t('Time Remaining')}: {minutes.toString().padStart(2, '0')}:
                    {seconds.toString().padStart(2, '0')}
                  </p>
                ) : (
                  <p>
                    {t('Time Remaining')}: {'00'}:{'00'}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="fs-14  pl-3 pl-md-0 pr-3 mb-3">
              {!timeoutError?.show ? (
                <p>
                  {t('Time Remaining')}: {minutes.toString().padStart(2, '0')}:
                  {seconds.toString().padStart(2, '0')}
                </p>
              ) : (
                <p>
                  {t('Time Remaining')}: {'00'}:{'00'}
                </p>
              )}
            </div>
          )}
          <Accordion
            activeKey={open[parseInt(defaultTab)] && defaultTab}
            className={`booking_accordion`}
          >
            {isMobile && (
              <Card className="">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  onClick={() => {
                    toggleHandle(GUEST_CHECKOUT ? -1 : 0);
                  }}
                  className={open[0] ? 'active' : ''}
                >
                  {/* <div className="number-circle">1</div> */}
                  {/* <span>{t("Please enter contact information")}</span> */}
                  <article>
                    <h2>
                      <span>1.&nbsp;</span>
                      {t('Cash Cards')} {t('Details')}
                    </h2>
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse id="collapse-padding" eventKey="0">
                  <article className="w-100 payment-booking-details booking_accordion_step">
                    {/* NEW DESIGN */}
                    {cashCardReservationLoading ? (
                      <div className="row">
                        <div className="col-12 text-center">
                          <Spinner />
                        </div>
                      </div>
                    ) : (
                      <CashCardInfoPayment />
                    )}
                    {!GUEST_CHECKOUT && (
                      <button
                        className="btn-main payment-submit-btn mt-3"
                        onClick={() => toggleHandle(1)}
                      >
                        {t('Confirm')}
                      </button>
                    )}
                  </article>
                </Accordion.Collapse>
              </Card>
            )}

            {GUEST_CHECKOUT && (
              <Card className={``}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={getStepWithOffset('-1', GUEST_CHECKOUT)}
                  onClick={(e) => {
                    if (loggedInUser) return;
                    // if (confirmDetails) return;
                    // if (bookingSuccessful === true) {
                    //   e.preventDefault();
                    //   e.stopPropagation();
                    // }
                    loggedInUser && isMobile ? toggleHandle(0) : toggleHandle(-1);
                  }}
                  className={
                    (
                      isMobile
                        ? open[getStepWithOffset(0, GUEST_CHECKOUT)]
                        : open[getStepWithOffset(-1, GUEST_CHECKOUT)]
                    )
                      ? 'active'
                      : ''
                  }
                >
                  {/* <div className="number-circle">{
                        getStepWithOffset(isMobile ? "1" : "0", GUEST_CHECKOUT)
                      }</div> */}
                  <article>
                    <h2>
                      <span>
                        {getStepWithOffset(isMobile ? '1' : '0', GUEST_CHECKOUT)}
                        .&nbsp;
                      </span>
                      {t('Sign in/Sign up to become a member')}
                    </h2>
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey={getStepWithOffset('-1', GUEST_CHECKOUT)}
                  id="collapse-padding"
                >
                  <article className="booking_accordion_step">
                    <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto payment_auth_options my-0">
                      <>
                        <button
                          className={`btn-main mx-auto guest_btn`}
                          onClick={(e) => {
                            // if (bookingSuccessful === true) {
                            //   e.preventDefault();
                            //   e.stopPropagation();
                            // }
                            setMemberBool('guestCheckout');
                            setSwitchTo('guestCheckout');
                            if (isMobile) {
                              toggleHandle(1);
                            } else {
                              toggleHandle(0);
                            }
                          }}
                        >
                          {t('Guest Checkout')}
                        </button>
                        <button
                          className={`btn-main mx-auto`}
                          onClick={(e) => {
                            // if (bookingSuccessful === true) {
                            //   e.preventDefault();
                            //   e.stopPropagation();
                            // }
                            setMemberBool('login');
                            setSwitchTo('login');
                            if (isMobile) {
                              toggleHandle(1);
                            } else {
                              toggleHandle(0);
                            }
                          }}
                        >
                          {t('Sign In')}
                        </button>
                      </>
                    </div>
                    <div className="mt-3">
                      <p
                        className="text-center not_a_member"
                        onClick={(e) => {
                          //   if (bookingSuccessful === true) {
                          //     e.preventDefault();
                          //     e.stopPropagation();
                          //   }
                          setMemberBool('register');
                          setSwitchTo('register');
                          if (isMobile) {
                            toggleHandle(1);
                          } else {
                            toggleHandle(0);
                          }
                        }}
                      >
                        {t('Not a member yet')}?{' '}
                        <a href="#">{t(`header-footer-menu.signup`)}</a>
                      </p>
                    </div>
                  </article>
                </Accordion.Collapse>
              </Card>
            )}

            <Card className={``}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={getStepWithOffset(isMobile ? '1' : '0', GUEST_CHECKOUT)}
                onClick={(e) => {
                  //   if (bookingSuccessful === true) {
                  //     e.preventDefault();
                  //     e.stopPropagation();
                  //     return false;
                  //   }
                  if (isMobile) {
                    toggleHandle(1);
                  } else {
                    toggleHandle(0);
                  }
                }}
                className={`
                    ${
                      (
                        isMobile
                          ? open[getStepWithOffset(1, GUEST_CHECKOUT)]
                          : open[getStepWithOffset(0, GUEST_CHECKOUT)]
                      )
                        ? 'active'
                        : ''
                    } 
                    ${
                      (isMobile
                        ? open[getStepWithOffset(1, GUEST_CHECKOUT)]
                        : open[getStepWithOffset(0, GUEST_CHECKOUT)]) && 'inactive'
                    }`}
              >
                <article>
                  <h2>
                    <span className="">
                      {getStepWithOffset(isMobile ? '2' : '1', GUEST_CHECKOUT)}
                      .&nbsp;
                    </span>
                    {switchTo !== 'guestCheckout' || !guestUser
                      ? t(
                          'Verify your details', // "Sign in/Sign up to become a member"
                        )
                      : t('Verify your details as guest')}
                  </h2>
                  {!isMobile && (open[0] != 1 || open[0] != true) && (
                    <>
                      <p className="text-uppercase text-primary">
                        {memberBool !== 'guestCheckout' ? (
                          !!guestUser && switchTo == 'guestCheckout' ? (
                            t('Guest Checkout')
                          ) : (
                            <>
                              {
                                ''
                                // t(`header-footer-menu.Sign in`) } / { t(`header-footer-menu.signup`)
                              }
                            </>
                          )
                        ) : (
                          t('Guest Checkout')
                        )}
                      </p>
                      {switchTo === 'guestCheckout' && email && <p>{email}</p>}
                    </>
                  )}
                </article>
              </Accordion.Toggle>
              {/* {defaultTab == "0" ? ( */}
              <Accordion.Collapse
                eventKey={getStepWithOffset(isMobile ? '1' : '0', GUEST_CHECKOUT)}
                id="collapse-padding"
              >
                {/* <> */}
                {guestUser && switchTo === 'guestCheckout' ? (
                  <>
                    <form
                      onSubmit={(e) => {
                        onClickLoginConfirmDetails(e, guestUser);
                      }}
                      className="booking_accordion_step"
                    >
                      <div className="custom-form col-md-10 col-lg-9 col-xl-7 px-0 mx-auto">
                        <div>
                          <label>
                            <span> {t('login_form.Email')}</span>
                          </label>
                          <input
                            disabled={guestUser ? true : undefined}
                            ref={emailInput}
                            type="email"
                            className="form-control"
                            placeholder={t('placeholder.Enter your Email ID')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />

                          <p className="error">
                            {loginSubmitted &&
                              email === '' &&
                              t('login_form.error_messages.Email is required')}
                          </p>
                        </div>
                        <div className="row justify-content-between align-items-center">
                          <div className="col-lg-6 pr-lg-2">
                            <label>
                              <span>{t('login_form.First Name')}</span>
                            </label>
                            <input
                              ref={guestFirstNameInput}
                              type="text"
                              className="form-control"
                              placeholder={t(
                                'login_form.placeholders.Enter your first name',
                              )}
                              value={guestFirstName}
                              onChange={(e) => setGuestFirstName(e.target.value)}
                            />
                            <p className="error">
                              {loginSubmitted &&
                                guestFirstNameInput === '' &&
                                t('field_required')}
                            </p>
                          </div>
                          <div className="col-lg-6 pl-lg-2">
                            <label>
                              <span> {t('login_form.Last Name')}</span>
                            </label>
                            <input
                              ref={guestLastNameInput}
                              type="text"
                              className="form-control"
                              placeholder={t(
                                'login_form.placeholders.Enter your last name',
                              )}
                              value={guestLastName}
                              onChange={(e) => setGuestLastName(e.target.value)}
                            />

                            <p className="error">
                              {loginSubmitted &&
                                guestLastNameInput === '' &&
                                t('field_required')}
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <label className="w-100">
                            <span className="d-inline-block mb-2">
                              {' '}
                              {t('login_form.Phone Number')}
                            </span>
                            <div className="d-flex">
                              <CountryCodeDropdown
                                className="form-control mr-2"
                                onSelectCountry={({
                                  country_id,
                                  country_mob_code,
                                }) => {
                                  setCountryId(country_id);
                                  setCountryMobCode(country_mob_code);
                                  setCountryMobCodeGuest(country_mob_code);
                                }}
                              />

                              <input
                                ref={mobileInput}
                                type="number"
                                min="10"
                                className="form-control mb-0"
                                placeholder={t(
                                  'login_form.placeholders.Enter your phone number',
                                )}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                              />
                            </div>
                          </label>
                        </div>
                        <p className="error"></p>

                        <div className="pt-4 pb-3">
                          <button
                            className="payment-submit-btn btn-main"
                            type="submit"
                          >
                            {t('Confirm')}
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                ) : loggedInUser ? (
                  // USER CONTACT INFO START
                  <>
                    <form
                      onSubmit={(e) => onClickLoginConfirmDetails(e)}
                      className="booking_accordion_step"
                    >
                      <div className="custom-form col-md-10 col-lg-9 col-xl-7 px-0 mx-auto">
                        <div>
                          <label>
                            <span> {t('login_form.Email')}</span>
                          </label>
                          <input
                            ref={emailInput}
                            type="email"
                            className="form-control"
                            placeholder={t('placeholder.Enter your Email ID')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />

                          {/* {loginSubmitted && email === "" && ( */}
                          <p className="error">
                            {loginSubmitted &&
                              email === '' &&
                              t('login_form.error_messages.Email is required')}
                          </p>
                          {/* )} */}
                        </div>
                        <label>
                          <span> {t('login_form.Phone Number')}</span>
                        </label>
                        <div class="d-flex">
                          <CountryCodeDropdown
                            className="form-control mr-2"
                            onSelectCountry={({ country_id, country_mob_code }) => {
                              setCountryId(country_id);
                              setCountryMobCode(country_mob_code);
                            }}
                          />

                          <input
                            ref={mobileInput}
                            type="number"
                            min="10"
                            className="form-control mb-0"
                            placeholder={t(
                              'login_form.placeholders.Enter your phone number',
                            )}
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                          />

                          {/* {loginSubmitted && mobile === "" && ( */}

                          {/* )} */}
                        </div>
                        <p className="error">
                          {loginSubmitted &&
                            mobile === '' &&
                            t('login_form.error_messages.Phone number is required')}
                        </p>
                        {/* <div className="agree-check">
                             <input
                               className="form-check-input"
                               type="checkbox"
                               name="exampleRadios"
                               id="exampleRadios1"
                               checked={loggedInConditionsCheck}
                               onChange={(e) => setLoggedInConditionsCheck(e.target.checked)}
                             />
                             <label className="form-check-label pt-1 pl-1" for="exampleRadios1">
                               I agree to all Terms & Conditions
                             </label>
                             {loginConditionsCheckError && (
                               <p className="error">
                                 You must agree to all Terms & Conditions
                               </p>
                             )}
                           </div> */}

                        <div class="pt-4 pb-3">
                          <button
                            className="payment-submit-btn btn-main"
                            type="submit"
                          >
                            {t('Confirm')}
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* <div className="next-step pb-3">
                          <p>{t("Next Step")}</p>
                          <div>
                            <img
                              src={greyArrowDown}
                              alt="Arrow Down"
                              className="grey-arrow-down"
                            />
                          </div>
                        </div> */}
                  </>
                ) : (
                  <>
                    <form
                      onSubmit={(e) => onClickRegisterConfirmDetails(e)}
                      className="booking_accordion_step"
                    >
                      <div className="custom-form col-lg-9 col-xl-7 px-0 mx-auto">
                        {/* <div
                              className="register-btn mx-auto"
                              onClick={() => setLogin(true)}
                              onMouseOver={() => setLoginIconBool(true)}
                              onMouseOut={() => setLoginIconBool(false)}
                            >
                              <button
                                className="payment-submit-btn"
                                onClick={() => setMemberBool("login")}
                              >
                                 <img
                                  src={
                                    loginIconBool && !isMobile
                                      ? activeLoginIcon
                                      : loginIcon
                                  }
                                  className="img-fluid pr-3"
                                  alt=""
                                /> 
                                {t("Login")}
                              </button>
                            </div>
                            <div className="or-div">
                              <p className="or-text">
                                <span>{t("Or")}</span>
                              </p>
                            </div>
                            <div>
                              <button
                                className={`mb-3 payment-submit-btn btn-main ${
                                  registerLoading && "loader-text"
                                }`}
                                type="submit"
                                onClick={() => setMemberBool("register")}
                              >
                                {t("Register")}
                              </button>
                            </div> */}
                        {/* {memberBool ? ( */}
                        {memberBool !== 'guestCheckout' && (
                          <div className="d-flex w-100 justify-content-around align-items-center mb-3 register_tabs">
                            {memberBool !== 'guestCheckout' && (
                              <div
                                className={`w-100 tabs py-2 ${
                                  memberBool === 'login' && 'active'
                                }`}
                                onClick={() => {
                                  setMemberBool('login');
                                }}
                              >
                                {t(`header-footer-menu.Sign in`)}
                              </div>
                            )}

                            {memberBool !== 'guestCheckout' && (
                              <div
                                className={`w-100 tabs py-2 ${
                                  memberBool === 'register' && 'active'
                                }`}
                                onClick={() => {
                                  setMemberBool('register');
                                }}
                              >
                                {/* {t(`login_form.Login`)} */}
                                {t(`header-footer-menu.signup`)}
                              </div>
                            )}

                            {/* {memberBool === "guestCheckout" && (
                                <div
                                  className={`w-100 tabs py-2 ${memberBool === "guestCheckout" && "active"
                                    }`}
                                  onClick={() => {
                                    setMemberBool("guestCheckout");
                                  }}
                                >
                                  {t("Guest Checkout")}
                                </div>
                              )} */}
                          </div>
                        )}
                        <div
                          className={`w-100 login-popup ${
                            memberBool === 'login' ? 'd-block' : 'd-none'
                          }`}
                        >
                          <div className="w-100 user-form">
                            <label className="mb-0">
                              <span>
                                {t('login_form.placeholders.email-mobile')}
                              </span>
                            </label>
                            <div className="d-flex">
                              {isNumber && (
                                <CountryCodeDropdown
                                  className="country_code_drop mr-2"
                                  onSelectCountry={({
                                    country_id,
                                    country_mob_code,
                                  }) => {
                                    setCountryId(country_id);
                                    setCountryMobCode(country_mob_code);
                                  }}
                                />
                              )}
                              <input
                                type="text"
                                placeholder={t(`Enter_email_or_mobile`)}
                                value={loginField}
                                onChange={(e) => setLoginField(e.target.value)}
                              />
                            </div>

                            <p className="error">
                              {(loginSubmitted || loginSubmittedViaOTP) &&
                                loginField === '' &&
                                t('field_required')}
                            </p>

                            <label className="position-relative mb-0">
                              <span>{t('login_form.Password')}</span>
                              <input
                                type={showLoginPassword ? 'text' : 'password'}
                                autocomplete="new-password"
                                placeholder={t(
                                  'login_form.placeholders.Enter your password',
                                )}
                                value={loginPassword}
                                min="4"
                                onChange={(e) => setLoginPassword(e.target.value)}
                              />
                              {!showLoginPassword && (
                                <img
                                  width="30"
                                  height="30"
                                  className="show-password-icon"
                                  src={showPasswordIcon}
                                  onClick={() => setShowLoginPassword((x) => !x)}
                                  alt=""
                                />
                              )}
                              {showLoginPassword && (
                                <img
                                  width="30"
                                  height="30"
                                  className="show-password-icon"
                                  src={hidePasswordIcon}
                                  onClick={() => setShowLoginPassword((x) => !x)}
                                  alt=""
                                />
                              )}
                            </label>
                            <p className="error">
                              {loginSubmitted &&
                                loginPassword === '' &&
                                type === 'email' &&
                                t('login_form.error_messages.Password is required')}
                            </p>

                            {loginError &&
                            loginPassword !== '' &&
                            type === 'email' ? (
                              <p className="error">
                                {t(loginError)},{' '}
                                <span
                                  style={{ cursor: `pointer` }}
                                  onClick={onErrorHandling}
                                >
                                  {loginError === 'Password is incorrect' ? (
                                    <u>
                                      {t(`please click here to change password`)}
                                    </u>
                                  ) : (
                                    <u>{t(`please click here to sign up`)}</u>
                                  )}
                                </span>
                              </p>
                            ) : null}
                            {loginOtpError && type === 'otp' ? (
                              <p className="error">
                                {/* {t(loginOtpError)},{" "} */}
                                <span
                                  style={{ cursor: `pointer` }}
                                  onClick={() => {
                                    setMemberBool('register');
                                    // setLoginSubmitted(false);
                                  }}
                                >
                                  <u>{t('please click here to sign up')}</u>
                                </span>
                              </p>
                            ) : null}

                            <button
                              type="button"
                              onClick={onSubmitLogin}
                              className={`payment-submit-btn btn-main mt-3 ${
                                loading && 'loader-text'
                              }`}
                            >
                              {t('login_form.Login')}
                            </button>
                          </div>

                          <div className="login-popup__actions">
                            <button
                              className="btn-basic"
                              onClick={onClickForgotPassword}
                            >
                              {t(`login_form.forgot-password`)}
                            </button>

                            {/* <button
                              onClick={onClickLoginViaOTP}
                              className={`btn-basic ${loadingOtp && 'loader-text'}`}
                            >
                              {t(`Login via OTP`)}
                            </button> */}
                          </div>
                          <div className="mt-3">
                            <p
                              className="text-center not_a_member"
                              onClick={() => setMemberBool('register')}
                            >
                              {t('Not a member yet')}?{' '}
                              <a href="#">{t(`header-footer-menu.signup`)}</a>
                            </p>
                          </div>
                        </div>

                        {/* Login Via OTP */}
                        <div
                          className={`w-100 login-popup ${
                            memberBool === 'otp-login' ? 'd-block' : 'd-none'
                          }`}
                        >
                          <LoginOtp
                            allowResendLogin={allowResendLogin}
                            setAllowResendLogin={setAllowResendLogin}
                            onClickResendLogin={onClickResendLogin}
                            onSuccess={() => setMemberBool('login-success')}
                            closeFunc={() => setMemberBool('login')}
                          />
                        </div>

                        <div
                          className={`w-100 login-popup ${
                            memberBool === 'forgot-password' ? 'd-block' : 'd-none'
                          }`}
                        >
                          <ForgotPasswordOtp
                            onClickResend={onClickResend}
                            allowResend={allowForgotPasswordResend}
                            setAllowResend={setAllowForgotPasswordResend}
                            field={fpField}
                            setField={setFpField}
                            onSuccess={() =>
                              setMemberBool('forgot-password-success')
                            }
                            closeFunc={() => setMemberBool('login')}
                          />
                        </div>

                        {/* ) : ( */}
                        <div
                          className={`w-100 ${
                            memberBool === 'register' ? 'd-block' : 'd-none'
                          }`}
                        >
                          <div>
                            <label className="">
                              <span>{t('login_form.Email')}</span>
                            </label>
                            <input
                              ref={emailRegisterInput}
                              type="email"
                              className="form-control"
                              placeholder={t(
                                'login_form.placeholders.Enter your email',
                              )}
                              value={registerEmail}
                              onChange={(e) => setRegisterEmail(e.target.value)}
                            />

                            {/* {submitted && registerEmail === "" && ( */}
                            <p className="error">
                              {submitted &&
                                registerEmail === '' &&
                                t('login_form.error_messages.Email is required')}
                            </p>
                            {/* )} */}
                          </div>
                          <label>
                            <span>{t('login_form.Phone Number')}</span>
                          </label>
                          <div className="d-flex">
                            <CountryCodeDropdown
                              className="form-control mr-2"
                              onSelectCountry={({
                                country_id,
                                country_mob_code,
                              }) => {
                                setCountryId(country_id);
                                setCountryMobCode(country_mob_code);
                              }}
                            />
                            <input
                              ref={mobileRegisterInput}
                              type="number"
                              min="9"
                              className="form-control"
                              placeholder={t(
                                'login_form.placeholders.Enter your phone number',
                              )}
                              value={registerMobile}
                              onChange={(e) => setRegisterMobile(e.target.value)}
                            />
                            {/* {submitted && registerMobile === "" && ( */}

                            {/* )} */}
                          </div>

                          <p className="error">
                            {submitted &&
                              registerMobile === '' &&
                              t(
                                'login_form.error_messages.Phone number is required',
                              )}
                          </p>

                          <div className="w-100">
                            <label class="w-100">
                              <span>{t('login_form.DateOfBirth')}</span>
                            </label>
                            <div className="w-100 position-relative">
                              <input
                                className="form-control"
                                type="date"
                                id="start"
                                name="dateofbirth"
                                placeholder={t('login_form.DateOfBirth')}
                                min="1920-01-01"
                                max={moment().format('YYYY-MM-DD')}
                                onChange={(e) => {
                                  setSelectedDate(e?.target?.value);
                                }}
                              />
                            </div>

                            <p className="error">
                              {submitted &&
                                !selectedDate &&
                                dobError &&
                                t('Please enter event date')}
                            </p>
                          </div>

                          <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6 pr-lg-2">
                              <label>
                                <span>{t('login_form.First Name')}</span>
                              </label>
                              <input
                                ref={fNameRegisterInput}
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  'login_form.placeholders.Enter your first name',
                                )}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />

                              {/* {submitted && firstName === "" && ( */}
                              <p className="error">
                                {submitted &&
                                  firstName === '' &&
                                  t(
                                    'login_form.error_messages.First name is required',
                                  )}
                              </p>
                              {/* )} */}
                            </div>
                            <div className="col-lg-6 pl-lg-2">
                              <label>
                                <span> {t('login_form.Last Name')}</span>
                              </label>
                              <input
                                ref={lnameRegisterInput}
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  'login_form.placeholders.Enter your last name',
                                )}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />

                              {/* {submitted && lastName === "" && ( */}
                              <p className="error">
                                {submitted &&
                                  lastName === '' &&
                                  t(
                                    'login_form.error_messages.Last name is required',
                                  )}
                              </p>
                              {/* )} */}
                            </div>
                          </div>
                          <label>
                            <span> {t('login_form.Password')}</span>
                          </label>
                          <div className="position-relative">
                            <input
                              ref={passwordRef}
                              type={showSignupPassword ? 'text' : 'password'}
                              autocomplete="new-password"
                              className="form-control mb-0"
                              placeholder={t(
                                'login_form.placeholders.Enter your password',
                              )}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {!showSignupPassword && (
                              <img
                                width="30"
                                height="30"
                                className="show-password-icon show-password-icon-2"
                                src={showPasswordIcon}
                                onClick={() => setShowSignupPassword((x) => !x)}
                                alt=""
                              />
                            )}
                            {showSignupPassword && (
                              <img
                                width="30"
                                height="30"
                                className="show-password-icon show-password-icon-2"
                                src={hidePasswordIcon}
                                onClick={() => setShowSignupPassword((x) => !x)}
                                alt=""
                              />
                            )}
                            {/* {submitted && password === "" && ( */}

                            {/* )} */}
                            <p className="error">
                              {submitted &&
                                password === '' &&
                                t('login_form.error_messages.Password is required')}
                            </p>
                          </div>

                          <div className="agree-check mb-1">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="exampleRadios"
                              id="exampleRadios1"
                              checked={registerConditionsCheck}
                              onChange={(e) =>
                                setRegisterConditionsCheck(e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label pt-1 pl-1"
                              for="exampleRadios1"
                            >
                              {/* {t("I agree to all Terms & Conditions")} */}
                              {t('I agree to all')}{' '}
                              <a href="/tnc" target="_blank">
                                {t('Terms & Conditions')}
                              </a>
                            </label>
                            {/* {registerConditionsCheckError && ( */}

                            {/* )} */}
                            {/* {registerError && <p>{registerError}</p>} */}
                          </div>
                          <p className="error">
                            {submitted && !registerConditionsCheck && (
                              <>
                                {t('You must agree to all Terms & Conditions')}
                                <br /> <br />
                              </>
                            )}
                            {registerError && <>{t(registerError)}</>}
                          </p>
                          {/* <p className="error">
                            </p> */}
                          {/* <div>
                              <button
                                className={`payment-submit-btn btn-main ${
                                  registerLoading && "loader-text"
                                }`}
                                type="submit"
                              >
                                {t("Continue")}
                              </button>
                            </div>
                            <div className="or-div">
                              <p className="or-text">
                                <span>{t("Or")}</span>
                              </p>
                            </div>
                            <div
                              className="register-btn w-75 mx-auto"
                              onClick={() => setLogin(true)}
                              onMouseOver={() => setLoginIconBool(true)}
                              onMouseOut={() => setLoginIconBool(false)}
                            >
                              <button className="payment-submit-btn">
                                <img
                                  src={
                                    loginIconBool && !isMobile
                                      ? activeLoginIcon
                                      : loginIcon
                                  }
                                  className="img-fluid pr-3"
                                  alt=""
                                />
                                {t("Login")}
                              </button>
                            </div> */}
                          <div>
                            <button
                              className={`payment-submit-btn btn-main
                                   ${registerLoading && 'loader-text'}
                                    `}
                              type="submit"
                            >
                              {t('Continue')}
                            </button>
                          </div>
                        </div>
                        <div
                          className={`w-100 ${
                            memberBool === 'guestCheckout' ? 'd-block' : 'd-none'
                          }`}
                        >
                          <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6 pr-lg-2">
                              <label>
                                <span>{t('login_form.First Name')}</span>{' '}
                              </label>
                              <input
                                ref={fNameRegisterInput}
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  'login_form.placeholders.Enter your first name',
                                )}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                              <p className="error">
                                {submitted &&
                                  firstName === '' &&
                                  t(
                                    'login_form.error_messages.First name is required',
                                  )}
                              </p>
                            </div>
                            <div className="col-lg-6 pl-lg-2">
                              <label>
                                <span>{t('login_form.Last Name')}</span>{' '}
                              </label>
                              <input
                                ref={lnameRegisterInput}
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  'login_form.placeholders.Enter your last name',
                                )}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                              <p className="error">
                                {submitted &&
                                  lastName === '' &&
                                  t(
                                    'login_form.error_messages.Last name is required',
                                  )}
                              </p>
                            </div>
                          </div>

                          <div>
                            <label>
                              <span>{t('login_form.Email')} </span>
                            </label>
                            <input
                              ref={emailRegisterInput}
                              type="email"
                              className="form-control"
                              placeholder={t(
                                'login_form.placeholders.Enter your email',
                              )}
                              value={registerEmail}
                              onChange={(e) => setRegisterEmail(e.target.value)}
                            />

                            <p className="error">
                              {submitted &&
                                registerEmail === '' &&
                                t('login_form.error_messages.Email is required')}
                            </p>
                          </div>

                          <div>
                            <label>
                              <span>{t('Confirm Email')}</span>{' '}
                            </label>
                            <input
                              ref={confirmemailRegisterInput}
                              type="email"
                              className="form-control"
                              placeholder={t(
                                'login_form.placeholders.Enter your email',
                              )}
                              value={confirmregisterEmail}
                              onChange={(e) =>
                                setconfirmRegisterEmail(e.target.value)
                              }
                            />

                            <p className="error">
                              {submitted &&
                                emailNotMatched &&
                                t('Email did not matched')}
                            </p>
                          </div>

                          <label>
                            <span>{t('login_form.Phone Number')}</span>{' '}
                          </label>
                          <div className="d-flex">
                            <CountryCodeDropdown
                              className="form-control mr-2"
                              onSelectCountry={({
                                country_id,
                                country_mob_code,
                              }) => {
                                setCountryId(country_id);
                                setCountryMobCode(country_mob_code);
                              }}
                            />
                            <input
                              ref={mobileRegisterInput}
                              type="number"
                              min="9"
                              className="form-control"
                              placeholder={t(
                                'login_form.placeholders.Enter your phone number',
                              )}
                              value={registerMobile}
                              onChange={(e) => setRegisterMobile(e.target.value)}
                            />
                            {/* {submitted && registerMobile === "" && ( */}

                            {/* )} */}
                          </div>
                          <p className="error"></p>

                          <div className="agree-check mb-1">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="exampleRadios"
                              id="exampleRadios1"
                              checked={registerConditionsCheck}
                              onChange={(e) =>
                                setRegisterConditionsCheck(e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label pt-1 pl-1"
                              for="exampleRadios1"
                            >
                              {/* {t("I agree to all Terms & Conditions")} */}
                              {t('I agree to all')}{' '}
                              <a href="/tnc" target="_blank">
                                {t('Terms & Conditions')}
                              </a>
                            </label>
                            {/* {registerConditionsCheckError && ( */}

                            {/* )} */}
                            {/* {registerError && <p>{registerError}</p>} */}
                          </div>
                          <p className="error">
                            {submitted && !registerConditionsCheck && (
                              <>
                                {t('You must agree to all Terms & Conditions')}
                                <br /> <br />
                              </>
                            )}
                            {registerError && <>{t(registerError)}</>}
                          </p>
                          <div>
                            <button
                              className={`payment-submit-btn btn-main ${
                                registerLoading && 'loader-text'
                              }`}
                              type="submit"
                            >
                              {t('Guest Checkout')}
                            </button>
                          </div>
                        </div>
                        {/* )} */}
                      </div>
                    </form>
                    {/* <div className="next-step">
                          <p>Next Step</p>
                          <div>
                            <img
                              src={greyArrowDown}
                              alt="Arrow Down"
                              className="grey-arrow-down"
                            />
                          </div>
                        </div> */}
                  </>
                )}

                {/* <PaymentOtp /> */}
                {/* </> */}
              </Accordion.Collapse>
              {/* ) : null} */}
            </Card>
            {/* {loggedInUser && loggedInConditionsCheck && isConfirmed && ( */}
            <Card className={``}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={getStepWithOffset('1', GUEST_CHECKOUT)}
                onClick={(e) => {
                  //   if (bookingSuccessful === true) {
                  //     e.preventDefault();
                  //     e.stopPropagation();
                  //     return false;
                  //   }
                  loggedInUser && isMobile ? toggleHandle(2) : toggleHandle(1);
                }}
                className={`${
                  (
                    isMobile
                      ? open[getStepWithOffset(2, GUEST_CHECKOUT)]
                      : open[getStepWithOffset(1, GUEST_CHECKOUT)]
                  )
                    ? 'active'
                    : ''
                }
                         ${
                           !(
                             loggedInUser &&
                             loggedInConditionsCheck &&
                             isConfirmed
                           ) && 'inactive'
                         }
                    `}
              >
                {/* <div className="number-circle">{
                      getStepWithOffset(isMobile ? "3" : "2", GUEST_CHECKOUT)
                    }</div> */}
                <article>
                  <h2>
                    <span>
                      {getStepWithOffset(isMobile ? '3' : '2', GUEST_CHECKOUT)}
                      .&nbsp;
                    </span>
                    {t('Choose Payment Mode')}
                  </h2>
                </article>
              </Accordion.Toggle>
              {/* {defaultTab == "1" ? ( */}
              <Accordion.Collapse
                eventKey={getStepWithOffset(isMobile ? '2' : '1', GUEST_CHECKOUT)}
                id="collapse-padding"
              >
                <div className="booking_accordion_step">
                  <PaymentSelectMode
                    email={email}
                    category_id={undefined}
                    toggleHandle={toggleHandle}
                    onConfirmPayment={onConfirmPayment}
                    t={t}
                    mobile={mobile}
                    countryMobCode={countryMobCode}
                  />
                </div>
                {/* <>
                    <PaymentCardDetails />
                  </> */}
              </Accordion.Collapse>
              {/* ) : null} */}
            </Card>
            {/* // )} */}
            {/* {loggedInUser &&
                  isConfirmed &&
                  bookingSuccessful === true &&
                  loggedInConditionsCheck && ( */}
            <Card className={` booking-success-accordian `}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={getStepWithOffset('2', GUEST_CHECKOUT)}
                // onClick={() => {
                //   isMobile ? toggleHandle(3) : toggleHandle(2);
                // }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className={`${
                  (
                    isMobile
                      ? open[getStepWithOffset(3, GUEST_CHECKOUT)]
                      : open[getStepWithOffset(2, GUEST_CHECKOUT)]
                  )
                    ? 'active'
                    : ''
                } 
                    ${
                      !(
                        loggedInUser
                        // && isConfirmed &&
                        // bookingSuccessful === true &&
                        // loggedInConditionsCheck
                      ) && 'inactive'
                    }`}
              >
                {/* <div className="number-circle">{
                      getStepWithOffset(isMobile ? "4" : "3", GUEST_CHECKOUT)
                    }</div> */}
                <article>
                  <h2>
                    <span>
                      {getStepWithOffset(isMobile ? '4' : '3', GUEST_CHECKOUT)}
                      .&nbsp;
                    </span>
                    {t('Confirmation')}
                  </h2>
                </article>
              </Accordion.Toggle>
              {/* {defaultTab == "2" ? ( */}
              <Accordion.Collapse
                eventKey={getStepWithOffset(isMobile ? '3' : '2', GUEST_CHECKOUT)}
                id="collapse-padding"
              >
                <div className="booking_accordion_step">
                  {/* <PaymentConfirmMessage t={t} /> */}
                  <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success">
                    {/* <div> */}
                    {/* <h4 className="font-weight-bold">Pay ₹{totalAmount}</h4> */}
                    {/* <p className="grey-font">Transaction #1234567889</p> */}
                    {/* </div> */}
                    <div className="">
                      <img
                        src={
                          cashCardReservation?.w_cash_card_image_url || giftCardImg
                        }
                        alt="Gift Card Img"
                        height="120"
                        width="200"
                        className=""
                      />
                      {/*style={{ width: 'unset' }}*/}
                    </div>
                    <div className="pt-2">
                      {/* <h2 className="font-weight-bold">
                             {t("Enjoy your movie!")}
                          </h2> */}
                      <p className="">
                        {/* {t("Your transaction was successfully processed, check your email for your tickets")} */}
                        {/*Your transaction was successfully processed,check your email / SMS for Gift card details*/}
                        {t('CashCard.SuccessMsg')}
                      </p>
                    </div>
                    <div className="mb-0">
                      <button
                        // className="payment-submit-btn"
                        className="btn-main btn-main-lg false false mx-auto"
                        onClick={() => {
                          //   console.log({ isUnpaidBooking });
                          //   if (isUnpaidBooking)
                          //     history.push(
                          //       `/unpaid-ticket/${reservation_id}/${category_id}`
                          //     );
                          //   else
                          //     history.replace(
                          //       `/final-ticket/${showBookingID}/${category_id}`
                          //     );
                          history.push(`/cash-cards`);
                        }}
                      >
                        {t('Close')}
                      </button>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

// Start Payment Select Mode
const PaymentSelectMode = ({
  email,
  category_id,
  toggleHandle,
  onConfirmPayment,
  t,
  mobile,
  countryMobCode,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  // const reservation_id = useSelector((state) => state.seatLayout.reservation_id);
  const { reservation_id } = useParams();
  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, guestUser } = loginDetails;
  const isSaferPayActive = useSelector((state) => state.movies.isSaferPayActive);
  const isSaferPayButtonDisable = useSelector(
    (state) => state.movies.isSaferPayButtonDisable,
  );
  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [showStripe, setShowStripe] = useState(false);
  const [is100PercentOff, setIs100PercentOff] = useState(false);
  const appliedVoucher = useSelector((state) => state.promotions.applied_voucher);
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const reservationDetails = useSelector(
    (state) => state.movies?.reservationDetails,
  );
  const booking_type_id = reservationDetails?.booking_type_id ?? null;

  const makeUnpaidBooking = () => {
    // console.log({ booking_type_id });
    // setUnpaidBookingLoading(true);
    // dispatch(
    //   unpaidBooking({
    //     reservation_id,
    //     cust_id: loggedInUser?.cust_id,
    //     cinema_id: scheduleDetails.cinema_id,
    //     category_id,
    //     callback: setUnpaidBookingLoading,
    //     secondCallback: toggleHandle,
    //     booking_type_id,
    //   })
    // );
    // history.push(`/unpaid-ticket/${reservation_id}/${category_id}`);
  };

  const MakeSaferPayPayment = () => {
    setSaferPayLoading(true);
    let payload = {
      email,
      reservation_id,
      callback: setSaferPayLoading,
      secondCallback: toggleHandle,
    };
    setShowStripe(false);
    dispatch(makeSaferPayPaymentForCashCard(payload));
  };

  const MakeOfferPayment = () => {
    setOfferpaymentLoading(true);
    console.log('Offer payment');

    // dispatch(
    //   makeOfferPaymentBooking({
    //     reservation_id,
    //     cust_id: loggedInUser?.cust_id,
    //     email,
    //     cinema_id: scheduleDetails.cinema_id,
    //     category_id,
    //     callback: setOfferpaymentLoading,
    //     secondCallback: toggleHandle,
    //     cinema_name: scheduleDetails.cine_name,
    //     mobile,
    //     countryMobCode,
    //     payment_mode_id: 13, // payment_mode_id = 13 = Vouchers applied
    //     booking_type_id,
    //   })
    // );
  };
  const onApplyPromoCode = () => {
    setIs100PercentOff(false);

    if (!promoCode || promoCode.length === 0) {
      // toast.dismiss();
      // toast.error("Please enter a promo code");
      return;
    }
    setPromoCodeError(null);

    let voucher = {
      cinema_id: scheduleDetails.cinema_id,
      cinema_name: scheduleDetails.cine_name,
      isTicket: true,
      md_id: scheduleDetails.md_id,
      net_amount: reservationDetails?.net_amount,
      total_amount: reservationDetails?.amount,
      total_ticket: scheduleDetails?.selectedSeats?.length,
      voucher_title: promoCode,
      voucher_code: promoCode,
    };

    console.log(voucher);

    // dispatch(validateVoucherCode(voucher))
    //   .then((response) => {
    //     console.log("response :>> ", response);
    //     let { is100PercentOff } = response;
    //     setIs100PercentOff(is100PercentOff);
    //     if (response && response.status === false) {
    //       setPromoCodeError(response.message);
    //     }
    //   })
    //   .catch((err) => {
    //     setIs100PercentOff(false);
    //     console.log("error applying voucher", err);
    //     setPromoCodeError(err.message);
    //   });
  };

  const [loading, setLoading] = useState(false);
  const [loadingOfferpayment, setOfferpaymentLoading] = useState(false);
  const [SaferPayloading, setSaferPayLoading] = useState(false);
  const [UnpaidBookingLoading, setUnpaidBookingLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const timerCompleted = useSelector((state) => state.movies.timerCompleted);
  let isPayBtnClick = useRef(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isPayBtnClick.current === true) {
      return;
    }

    // await stripe.create;
    isPayBtnClick.current = true;
    const response = await stripe.createToken(elements.getElement(CardElement), {
      currency: curr_code,
      account_holder_name: loggedInUser?.first_name,
      account_holder_type: 'individual',
    });
    console.log('response :>> ', response);

    if (response.token && !response.token.used) {
      setStripeError(null);
      setLoading(true);
      console.log(`reservation_id`, reservation_id);
      // let voucher = {
      //   cinema_id: scheduleDetails.cinema_id,
      //   cinema_name: scheduleDetails.cine_name,
      //   isTicket: true,
      //   md_id: scheduleDetails.md_id,
      //   net_amount: reservationDetails?.net_amount,
      //   total_amount: reservationDetails?.amount,
      //   total_ticket: scheduleDetails?.selectedSeats?.length ?? 0,
      //   voucher_title: promoCode,
      //   voucher_code: promoCode,
      // };
      if (timerCompleted === false) {
        dispatch(
          makeStripePaymentForCashCard({
            stripeToken: response.token,
            reservation_id,
            callback: setLoading,
            secondCallback: toggleHandle,
            email,
          }),
        );
        // dispatch(
        //   category_id == 1
        //     ? onSuccessStripeToken({
        //       reservation_id,
        //       token: response.token,
        //       cust_id: loggedInUser?.cust_id,
        //       email,
        //       cinema_id: scheduleDetails.cinema_id,
        //       category_id,
        //       callback: setLoading,
        //       secondCallback: toggleHandle,
        //       cinema_name: scheduleDetails.cine_name,
        //       mobile,
        //       countryMobCode,
        //       appliedVoucher: !appliedVoucher ? null : voucher,
        //     })
        //     : onSuccessStripeTokenBrij({
        //       reservation_id,
        //       token: response.token,
        //       cust_id: loggedInUser?.cust_id,
        //       email,
        //       cinema_id: scheduleDetails.cinema_id,
        //       category_id,
        //       callback: setLoading,
        //       secondCallback: toggleHandle,
        //       cinema_name: scheduleDetails.cine_name,
        //       mobile,
        //       countryMobCode,
        //       appliedVoucher: !appliedVoucher ? null : voucher,
        //     })
        // );
      }

      // onConfirmPayment({stripe:true,})
    } else {
      isPayBtnClick.current = false;
      setStripeError(response.error.message);
      return;
    }
  };
  const onRemoveVoucher = () => {
    setIs100PercentOff(false);
    // dispatch(clearSelectedVoucher());
  };
  const bookSeatError = useSelector((state) => state.movies.bookSeatError);
  const activePaymentModes = useSelector(
    (state) => state.movies.activePaymentModes,
  );

  let isStripePaymentModeEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 14)?.length > 0 ?? false;

  let isUnpaidBookingEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 17)?.length > 0 ?? false;

  let ActivePaymentModeAvailable =
    activePaymentModes?.filter((p) => p?.pm_id == 15 || p?.pm_id == 14)?.length >
      0 ?? false;

  useEffect(() => {
    return () => {
      console.log('PaymentSelectMode unmounted');
      onRemoveVoucher();
    };
  }, []);

  return (
    <>
      {/* <div className="payment-mode col-md-10 col-lg-9 col-xl-7 px-0 mx-auto pt-3">
        <div>
          <img src={wallet} alt="wallet" />
          <h6
            className="text"
            onClick={() => loggedInUser && onConfirmPayment({ stripe: false })}
          >
            My Cinema Wallet
          </h6>
        </div> */}

      {/* <div>
          <img src={creditCard} alt="credit-card" />
          <h6 className="text">Credit / Debit Card</h6>
        </div> */}
      {/* <div>
          <img src={netBanking} alt="net-banking" />
          <h6 className="text">Netbanking</h6>
        </div> */}
      {/* <div>
          <img src={aliPay} alt="Ali Pay" className="grey-arrow-down" />
          <h6 className="text">Ali Pay</h6>
        </div>
      </div> */}
      <div
        className={`promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto ${
          appliedVoucher ? 'align-items-center' : ''
        }`}
      >
        {/*
        {!appliedVoucher ? (
          <div>
          <p>
            {t('Do you have a voucher or promo code?')}
        </p>
            <div className="promo_code_wrapper">
              <input
              type="text"
              className="form-control"
              placeholder={t("Apply your promo code here")}
              value={promoCode || ""}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button onClick={() => onApplyPromoCode()} className="btn-main guest_btn">
              {t("Apply")}
            </button>
            </div>
            {promoCodeError && (
              <p className="error mt-1"> {t(promoCodeError)}</p>
            )}
          </div>
        ) : (
          <>
            <p>
              {t("Voucher Applied")}: {appliedVoucher?.voucher_title}
            </p>
            <button
              onClick={onRemoveVoucher}
              className="promo-btn remove-code ml-2"
            >
              {t("Remove")}
            </button>
          </>
        )}
*/}
      </div>
      {!is100PercentOff && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3">
          <article className="payment-methods">
            {isStripePaymentModeEnabled &&
              (isSaferPayActive || isUnpaidBookingEnabled) && (
                <button
                  className={`btn-main`}
                  onClick={() => setShowStripe(!showStripe)}
                >
                  {t('Stripe')}
                </button>
              )}
            {isSaferPayActive && (
              <button
                className={`btn-main ${SaferPayloading && 'loader-text inactive'}`}
                disabled={isSaferPayButtonDisable}
                onClick={MakeSaferPayPayment}
              >
                {t('Safer Pay')}
              </button>
            )}
            {false &&
              !appliedVoucher &&
              isUnpaidBookingEnabled &&
              booking_type_id === 0 && (
                <button
                  className={`btn-main ${
                    UnpaidBookingLoading && 'loader-text inactive'
                  }`}
                  onClick={() => makeUnpaidBooking()}
                >
                  {t('Unpaid Booking')}
                </button>
              )}
          </article>
        </div>
      )}

      {!is100PercentOff &&
        (showStripe || (!isSaferPayActive && !isUnpaidBookingEnabled)) &&
        isStripePaymentModeEnabled && (
          <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3">
            {bookSeatError && <p className="error">{bookSeatError}</p>}
            <form onSubmit={handleSubmit}>
              {stripeKey && (
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: 'white',
                        '::placeholder': {
                          color: '#eeeeee',
                        },
                        padding: '10px',
                        border: '1px solid white',
                      },
                      invalid: {
                        color: '#dc3545',
                      },
                    },
                  }}
                />
              )}
              {stripeError && (
                <p className="error stripe-error">{t(stripeError)}</p>
              )}
              <button
                className={`btn-main mx-auto mt-3 ${
                  loading && 'loader-text inactive'
                }`}
                disabled={!stripe}
              >
                {t('Pay')}
              </button>
            </form>
          </div>
        )}

      {false && is100PercentOff && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${
              loadingOfferpayment && 'loader-text inactive'
            }`}
            onClick={() => {
              // MakeOfferPayment();
            }}
          >
            {t('Confirm')}
          </button>
        </div>
      )}
      {!ActivePaymentModeAvailable && !is100PercentOff && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-2 pb-3 d-flex">
          <p className="error stripe-error w-100 fs-16">
            {t('No Active Payment Modes Found')}
          </p>
        </div>
      )}
      {/* {isUnpaidBookingEnabled && booking_type_id === 0 && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${
              UnpaidBookingLoading && "loader-text inactive"
            }`}
            onClick={() => makeUnpaidBooking()}
          >
            {t("Unpaid Booking")}
          </button>
        </div>
      )} */}
    </>
  );
};
// End  Payment Select Mode

const PaymentFlow = ({ t }) => {
  const stripePublisherKeyFromStore = useSelector(
    (state) => state.movies.stripePublisherKey,
  );
  const stripePublisherKey =
    localStorage.getItem('stripe_publisher_key') || stripePublisherKeyFromStore;
  const stripePromise = loadStripe(stripePublisherKey);
  return (
    <Elements stripe={stripePromise}>
      <CashCardPayment t={t} />
    </Elements>
  );
};
export default withNamespaces()(PaymentFlow);
