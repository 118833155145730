import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getLangSpecificAttribute } from '@helper/languages';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const TnCNewContainer = ({ t }) => {
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  return (
    <>
      <div className="tnc_new align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={`${t('Terms')} & ${t('Conditions')}`}
          />
          <CenteredTitle firstHalf={`${t('Tnc_Title')}`} secondHalf={''} />
        </div>
        <section
          className="row"
          style={{
            direction: selected_language.lang_id == 3 ? 'rtl' : 'ltr',
            textAlign: selected_language.lang_id == 3 ? 'right' : '',
          }}
        >
          <article className="col-lg-10 col-xl-12 tnc_back">
            {/* <!--New design start--> */}
            <p className="tnc_new_text_highlighted mb-2">{t('Condition_of')}</p>
            <div>{t('In_line_width')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('Operators_and')}</p>
            <div>{t('When_using')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('Cinema_viewers')}</p>

            <p className="tnc_new_text_highlighted mb-2">{t('Talking.1')}</p>
            <div>{t('Viewers_must')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('Eating_smoking')}</p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('Eat_and_drink')}
            </div>
            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('Smoking_is_strictly')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">{t('Movie_Recording')}</p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('Viewers_are_strictly')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('Movie_recording_viewers')}
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {t('seating_ticketing')}
            </p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('viewers_are_not')}
            </div>
            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('viewers_must_retain')}
            </div>
            <div className="d-flex">
              <span className="pr-2">{t('Three_3')}.</span>
              {t('movie_attendance_viewers')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">{t('Clothing.1')}</p>
            <div className="d-flex">{t('Viewers_must_wear')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('To_the_parents')}</p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('Viewers_should_ensure')}
            </div>
            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('Children_below_the')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">{t('Security.1')}</p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('Cinema_operator')}
            </div>
            <p className="tnc_new_text_highlighted mb-2">{t('Other.1')}</p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('viewers_should_refrain')}
            </div>
            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('falling_to_follow')}
            </div>
            <div className="d-flex">
              <span className="pr-2">{t('Three_3')}.</span>
              {t('viewers_are_not_allowed')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">
              {t('operators_obligations')}
            </p>
            <div className="d-flex">{t('venue_condition')}</div>
            <div className="d-flex">{t('staffing_shall')}</div>
            <div className="d-flex">{t('movie_selection')}</div>
            <div className="d-flex">{t('ticket_type_avail')}</div>
            <div className="d-flex">{t('food_beverages_to')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('movie_exp')}</p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('operator_should_aim')}
            </div>
            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('operator_should_start')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">{t('operator_GCAM')}</p>

            <p className="tnc_new_text_highlighted mb-2">{t('Licensing.1')}</p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('operator_shall_abide')}
            </div>
            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('if_any_of_the')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">{t('Movie_Rating.1')}</p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('by_the_rating')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('each_cinema_operator')}
            </div>

            <div className="d-flex">{t('local_production')}</div>

            <div className="d-flex">{t('info_provision')}</div>

            <div className="d-flex">{t('should_cooperate')}</div>

            <div className="d-flex">{t('complaint_magement')}</div>

            <div className="d-flex">{t('movie_termination')}</div>

            <p className="tnc_new_text_highlighted mb-2">
              {t('General_Terms_and_Conditions.1')}
            </p>
            <p className="tnc_new_text_highlighted mb-2">{t('Returns_Policy.1')}</p>
            <div className="d-flex">{t('movie_ticket_sold')}</div>

            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('All_credit_cards')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('saudi_arabia_is')}
            </div>

            {/* <div className="d-flex">
              <span className="pr-2">{t("Three_3")}.</span>
              {t("will_not_deal_or")}
            </div> */}

            <div className="d-flex">
              <span className="pr-2">{t('Three_3')}.</span>
              {t('we_accept_payments')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Four_4')}.</span>
              {t('refunds_will_be')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">
              {t('Intellectual_Property.1')}
            </p>
            <div className="d-flex">
              <span className="pr-2">{t('One_1')}.</span>
              {t('empire_owns_all')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Two_2')}.</span>
              {t('modify_the_material')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Three_3')}.</span>
              {t('resell_the_material')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Four_4')}.</span>
              {t('create_derivative')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Five_5')}.</span>
              {t('we_may_withdraw')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Six_6')}.</span>
              {t('we_may_in_our_absolute')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Seven_7')}.</span>
              {t('participation_in_competitions')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Eight_8')}.</span>
              {t('a_personal_film')}
            </div>

            <div className="d-flex">
              <span className="pr-2">{t('Nine_9')}.</span>
              {t('any_other_reason')}
            </div>

            <div className="d-flex">
              <span className="pr-2">
                {t('One_1')} {t('Zero_0')}.
              </span>
              {t('by_submitting_info')}
            </div>

            <div className="d-flex">
              <span className="pr-2">
                {t('One_1')} {t('One_1')}.
              </span>
              {t('use_reproduce_modify')}
            </div>

            <div className="d-flex">
              <span className="pr-2">
                {t('One_1')} {t('Two_2')} .
              </span>
              {t('material_and_create')}
            </div>

            <div className="d-flex">
              <span className="pr-2">
                {t('One_1')} {t('Three_3')}.
              </span>
              {t('sublicense_any_of')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">{t('Linking.1')}</p>
            <div className="d-flex">{t('it_is_strictly')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('conduct.1')}</p>
            <div className="d-flex">{t('in_using_this')}</div>

            <div className="d-flex">
              <span className="pr-2 custom_whitespace">{t('One_1')} .</span>
              {t('disrupt_the_operation')}
            </div>

            <div className="d-flex">
              <span className="pr-2 custom_whitespace">{t('Two_2')} .</span>
              {t('use_this_website')}
            </div>

            <div className="d-flex">
              <span className="pr-2 custom_whitespace">{t('Three_3')} .</span>
              {t('submit_any_unlawful')}
            </div>

            <div className="d-flex">
              <span className="pr-2 custom_whitespace">{t('Four_4')} .</span>
              {t('submit_any_material')}
            </div>

            <div className="d-flex">
              <span className="pr-2 custom_whitespace">{t('Five_5')} .</span>
              {t('modify_or_delete')}
            </div>

            <div className="d-flex">
              <span className="pr-2 custom_whitespace">{t('Six_6')} .</span>
              {t('attempt_to_gain')}
            </div>

            <div className="d-flex">
              <span className="pr-2 custom_whitespace">{t('Seven_7')} .</span>
              {t('we_reserve_the_right')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">{t('security_and_acc')}</p>

            <div className="d-flex">
              <span className="pr-2 ">{t('One_1')} .</span>
              {t('this_website_may')}
            </div>

            <p className="tnc_new_text_highlighted mb-2">{t('third_part_sites')}</p>

            <div className="d-flex">{t('you_may_be_able_to')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('password.1')}</p>

            <div className="d-flex">{t('if_you_register_with')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('General.1')}</p>

            <div className="d-flex">{t('comman_law_duty')}</div>

            <p className="tnc_new_text_highlighted mb-2">{t('Disputes.1')}</p>

            <div className="d-flex">{t('any_dispute_between')}</div>

            {/* <!--Old design start--> */}
            {/* <div>
              <p>
                <span className="tnc_new_text_highlighted">
                  {t("generalTermsAndConditionsLine1Text1")}
                </span>{" "}
                {t("generalTermsAndConditionsLine1Text2")}
              </p>
            </div>

            <div>
              <p>
                <span className="tnc_new_text_highlighted">
                  {t("generalTermsAndConditionsLine2Text1")}
                </span>{" "}
                {t("generalTermsAndConditionsLine2Text2")}
              </p>
            </div>

            <p className="tnc_new_text_highlighted mb-2">
              {t("generalProvisions")}
            </p>
            <div>
              <ul>
                <li>{t("generalProvision1")}</li>
                <li>{t("generalProvision2")}</li>
                <li>{t("generalProvision3")}</li>
                <li>{t("generalProvision4")}</li>
                <li>{t("generalProvision5")}</li>
                <li>{t("generalProvision6")}</li>
              </ul>
            </div>

            <p className="text-primary">{t("ArtMediaProductionGmbH")}</p> */}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TnCNewContainer);
